import { Link, useNavigate } from "react-router-dom";
import "../Css/Footer.css"

function Footer()
{
    return(

        <div className="footer-and-copyright">
        <div className="footer">

        <div className="footer-overlay">

        <img id="footer-logo" src={require("../images/footerlogo.png")}></img>
        <p style={{marginTop:"-20px", color:"#ffffffc4"}}>شبکه سوارکاران فلیکا</p>

        <div  id="footer-links">

        <span className="footer-column">
            <h4>لینک های مفید</h4>
        <Link to="/pages"><p className="footer-link" >صفحه های فلیکا</p></Link>
        <Link to="/posts"><p className="footer-link">جدیدترین پست ها</p></Link>
        <Link to="/products"><p className="footer-link">محصولات سوارکاری</p></Link>
        </span>

        <span className="footer-column">
            <h4>هشتک های محبوب</h4>
        <Link to="/tags/باشگاه_سواری"><p className="footer-link" >#باشگاه_سواری</p></Link>
        <Link to="/tags/مسابقه"><p className="footer-link">#مسابقه</p></Link>
        <Link to="/tags/نژاد_اسب"><p className="footer-link">#نژاد_اسب</p></Link>
        </span>

        <span className="footer-column">
        <h4>راه های ارتباطی</h4>
        <p>info@flicka.ir</p>
        <p>واتساپ، تلگرام:</p>
        <p style={{direction:"rtl"}}>98935-23-33-881+</p>
        </span>

        </div>


        </div>
            </div>
        <div className="copyright">
            <p>کپی رایت 1403 - تمامی حقوق برای سایت فلیکا محفوظ است </p>
        </div>
        </div>

        
    );

}

export default Footer;