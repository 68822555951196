import Intro from "../components/Intro";
import "../Css/HomePage.css";
import Tag from "../components/Tag";
import "../Css/LoginPage.css";
import UsersCarousel from "../components/UsersCarasoule";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import {Helmet} from "react-helmet";
import Post from "../components/Post";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Article from "../components/Article";
import { Block } from "@material-ui/icons";

function HomePage() {
  
    const navigate = useNavigate();
    const [fetchedPages, setFetchedPages] = useState([]);
    const [fetchedPosts, setFetchedPosts] = useState([]);
    const [fetchedtags,setFetchedTags] = useState([]);
    const [fetchedHorseProduct,setFetchedHorseProducts]=useState([]);
    const [fetchedRiderProducts,setFetchedRiderProducts]=useState([]);
    const [fetchedArticles,setFetchedArticles] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallDialog, setShowInstallDialog] = useState(false);
    const [showIOSDialog,setShowIOSDialog] = useState(false);
  
    
    


  useEffect(() => {

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      return () => {
        window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
    
  }, []);



  useEffect(() => {
    const handleAppInstalled = () => {
      // Hide the install dialog when the app is installed
      setShowInstallDialog(false);

      //ios cannot be installed automatically, show the install guide:
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if(isIOS)
        setShowIOSDialog(true);

      setDeferredPrompt(null);


    };

    window.addEventListener("appinstalled", handleAppInstalled);
    return () => {
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);


  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    // setTimeout(() => {
      setDeferredPrompt(event);
      setShowInstallDialog(true);
    // }, 3000); // 3 second delay
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {

      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");

          //write in cache that user has installed the app:
          localStorage.setItem("FlickaAppInstalled", JSON.stringify(true));

        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
        setShowInstallDialog(false);
      });
    }
  };
    






  
  useEffect(() => {
    //If user is logged in , got to dashboard
    if (JSON.parse(localStorage.getItem("flickerNetwork")) != null)
      navigate("/feed");
  }, []);

   
   useEffect(() => {

    //fetching suggested pages:
    async function fetchPages() {
      
      
        axios
          .get(process.env.REACT_APP_BACK+"/pages/suggest/" + 6, config)
          .then((result) => {
            if (result.status == 200) {
              setFetchedPages(result.data);
              console.log("fetched suggested pages: ", result.data);
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
               navigate("/login")
          });
      
    }

    //fetching public posts:
    async function fetchPosts() {
      
      
      axios
        .get(process.env.REACT_APP_BACK+"/posts/public/" + 3, config)
        .then((result) => {
          if (result.status == 200) {
            setFetchedPosts(result.data);
            console.log("fetched public posts: ", result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
               navigate("/login")
        });
    
  }

  //fetching popular tags:
  async function fetchTags() {
      
      
    axios
      .get(process.env.REACT_APP_BACK+"/posts/popular_tags/", config)
      .then((result) => {
        if (result.status == 200) {
          setFetchedTags(result.data);
          console.log("fetched Popular Tags: ", result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
               navigate("/login")
      });
  
}

      //fetching horse products:
      async function fetchHorseProducts() {
            
            
        await axios.get(process.env.REACT_APP_BACK+"/posts/products/horse/"+3, config)
          .then((result) => {
            if (result.status == 200) {
              setFetchedHorseProducts(result.data);
              console.log("fetched horse products: ", result.data);
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
               navigate("/login")
          });

      }

      //fetching horse products:
      async function fetchRiderProducts() {
            
        await axios.get(process.env.REACT_APP_BACK+"/posts/products/rider/"+3, config)
          .then((result) => {
            if (result.status == 200) {
              setFetchedRiderProducts(result.data);
              console.log("fetched rider products: ", result.data);
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
               navigate("/login")
          });

      }

      async function fetchArticles()
      {
          
        await axios.get(process.env.REACT_APP_BACK+"/articles/all/"+3, config)
        .then((result) => {
          if (result.status == 200) {
            setFetchedArticles(result.data);
            console.log("fetched articles",result.data);
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
             navigate("/login")
        });

      }

    fetchPages();
    fetchPosts();
    fetchTags();
    fetchHorseProducts();
    fetchRiderProducts();
    fetchArticles();

  }, []);

  


  


  return (
    <div>

{/* meta tags: ********************************************************************************************************/}
      <Helmet>
     
      <title>شبکه سوارکاران فلیکا</title>
      <meta name="description" content="فلیکا یک شبکه اجتماعی برای دوستداران اسب و ورزش سوارکاری است"/>
      <meta name="robots" content="index, follow"/>
      <meta name="author" content={"Flicka"}/>
      <link rel="canonical" href={`${process.env.REACT_APP_SITE_URL}`}/>
      <meta property="og:title" content={'شبکه سوارکاران فلیکا'}/>
      <meta property="og:description" content={'فلیکا یک شبکه اجتماعی برای دوستداران اسب و ورزش سوارکاری است'}/>
      <meta property="og:image" content={`${process.env.REACT_APP_BACK}/avatars/flicka_logo.PNG`}/>
      <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}`}/>
      <meta property="og:type" content="article"/>
      <meta property="article:published_time" content={"2024-07-28"}/>
      <meta property="article:author" content={"Flicka"}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "headline": 'شبکه سوارکاران فلیکا',
                    "description": 'فلیکا یک شبکه اجتماعی برای دوستداران اسب و ورزش سوارکاری است',
                    "author": {
                      "@type": "Person",
                      "name": "Flicka"
                    },
                    "publisher": {
                      "@type": "Organization",
                      "name": "Flicka",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.flicka.ir/avatars/flicka_logo.PNG"
                      }
                    },
                    "datePublished":"2024-07-28"
                  })}
                </script>      
    </Helmet>
{/**********************************************************************************************************************/}


    
        <Dialog
        className="install-app"
        position="center"
        header="نصب اپلیکیشن"
        visible={showInstallDialog}
        onHide={() => setShowInstallDialog(false)}
      >
         <img src={require("../images/app.png")} />


        <p> می خواهید اپلیکیشن فلیکا را نصب کنید؟</p>
          <Button onClick={handleInstallClick}>بله!</Button>
          <Button id="cancel-install" onClick={() => setShowInstallDialog(false)} >نه، ممنون</Button>

         


      </Dialog> 

      <Dialog
        className="ios-guide"
        position="center"
        header="نصب اپلیکیشن"
        visible={showIOSDialog}
        onHide={() => setShowIOSDialog(false)}
        style={{width: !desktop ? "90%" : "40%"}}
      >

      <img className="install-logo" src={ require('../images/logo.png') } />
      <h5>
        وب اپلیکیشن فلیکا را به صفحه اصلی موبایل خود اضافه کنید
      </h5>
      <hr classname="dotted"/>
      <span style={{display:"flex",direction:"rtl",alignItems:"center"}}>
        <i className="pi pi-upload" style={{display:"flex",width:"10%"}}></i>
        <p> 1- در نوار پایین روی دکمه share بزنید</p>
        </span>

        <span style={{display:"flex",direction:"rtl",alignItems:"center"}}>
        <i className="pi pi-plus-circle" style={{display:"flex",width:"10%"}}></i>
        <p>
          2- در منوی بازشده، در قسمت پایین، گزینه add to Home Screen را انتخاب کنید
        </p>
        </span>

        <span style={{display:"flex",direction:"rtl",alignItems:"center",marginBottom:"30px"}}>
        <h5 style={{display:"flex",width:"10%",marginBottom: "0px"}}>Add</h5>
        <p>
          3- در مرحله بعد در قسمت بالا روی Add بزنید
        </p>
        </span>

        <Button> متوجه شدم</Button>

      </Dialog>





      <div className="top-box">
        <div className="login-pic">
          { desktop ? <img src={require("../images/homepage.png")} alt=" فلیکا ، شبکه اجتماعی سوارکاری"/> : <img src={require("../images/homepage2.png")} alt="فلیکا ، شبکه اجتماعی سوارکاری"/> }
        </div>

        <div className="info">
          <h1 className="title">شبکه سوارکاران فلیکا</h1>
          <p className="subtitle">
            فلیکا یک شبکه اجتماعی برای دوست داران اسب و ورزش سوارکاری است. فلیکا
            فضایی فراهم می کند برای اشتراک گذاری مطالب و تصاویر، ساخت پروفایل
            برای سوار و اسب، ساخت گروه های مختلف، برگزاری رویدادها و خرید و فروش
            لوازم سوارکاری. با فلیکا دوستان اسبی بیشتری خواهید داشت.
          </p>
          <Link to="/register">
            <button>ثبت نام کنید</button>
          </Link>
        </div>
      </div>

      <div className="intro-title">
        <h2>امکانات فلیکا</h2>
        <p>با ثبت‌نام در فلیکا چه امکاناتی در اختیار دارید؟ </p>
      </div>
      <Intro />

      <div className="search-tags">
        <h2>هر چیز که در جستن آنی، آنی!</h2>

        <div className="tags">
          <ul className="section1">

          
        {fetchedtags.map((tag, index) => (
          
            

            <Link  key={index} to={"/tags/"+tag.value}>
              <li>
                <Tag value={tag.value}></Tag>
              </li>
            </Link>

        ))}

          </ul>

        </div>

        <div>
        <button 
            style={{marginTop:"50px"}} 
            className="login-button"
            onClick={()=>navigate("/tags")}
            >
                مشاهده همه تگ ها</button>
          
          </div>
      </div>

      

      <div className="intro-title2">
        <h2>شبکه سوارکاران فلیکا</h2>
        <p>برای چه افرادی توسعه یافته؟</p>

        <UsersCarousel />
      </div>


      <div id="weblog-section" style={{background:"#ffffff"}}>


      <h2 style={{marginBottom:"0px"}}>مقاله های وبلاگ</h2>
      <p style={{marginBottom:"50px"}}>با آخرین اخبار و نکات دنیای سوارکاری آشنا شوید</p>
   
         <div style={{display: desktop ?'flex' : "block",gap:'30px'}}>
            {fetchedArticles?.map((article, index) => (
              <div key={index} style={{ width: desktop? '30%' : '100%', display: 'flex', gap: '30px' }}>
                <Article
                  article={article}
                  singleArticle={false}
                />
              </div>
            ))}

          </div>

          <button 
        style={{marginTop:"50px"}} 
        className="login-button"
        onClick={()=>navigate("/weblog")}
        >
            مشاهده همه مقاله ها</button>


        

      </div>




      <div id="pages-section">
        <h2>شاید از این صفحه ها خوشِتان بیاید</h2>

        {fetchedPages.map((page, index) => (
            
            page.type != "group" ? 

            <div className="intro-item" 
            key={index}
            
            onClick={() => {
              navigate("/" + page.username + "/about", { state: {page: page, isPage: true , isUserPage:false } });
              }}>
                <img className="pi pi-images" src={`${process.env.REACT_APP_BACK}/avatars/${page.avatarPic}`}  alt={`فلیکا ، شبکه اجتماعی سوارکاری - ${page.name}`}></img>
                <h4 className="title">{page.name}</h4>
                <p className="subtitle">{page.description}</p>
            </div>
            
            : null

          ))}

          <div>
        
            <button 
            style={{marginTop:"50px"}} 
            className="login-button"
            onClick={()=>navigate("/pages")}
            >
                مشاهده همه صفحه ها</button>
          
          </div>

      </div>

      <div id="posts-section" style={{background:"#ffffff"}}>


      <h2 style={{marginBottom:"0px"}}>آخرین محصولات</h2>
      <p>تمامی محصولات اسب و سوار از برترین برندهای خارجی و ایرانی را می توانید اینجا پیدا کنید</p>

      <h3 style={{textAlign:desktop ? "right" : "center",color:"#af734f",margin:desktop?"30px 80px 30px 0px":"50px 30px 30px 30px"}}>لوازم سوار</h3>
      <table>
          <tbody>
          <tr>
        {fetchedRiderProducts.map((post, index) => (
            
            post.type != "group" ? 

            <td className="post-item" 
            key={index}
            style={{backgroundColor: index===0 ? "white" : "transparent"}}
            onClick={() => {
              navigate("/posts/"+post._id,{ state: {isPublic: true} } );
              }}>

            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={true}
            _id={post._id}
        
          />
            </td>
            
            : null

          ))}

</tr>
</tbody>
          </table>



          <h3 style={{textAlign: desktop ? "right" : "center",color:"#af734f",margin: desktop?"30px 80px 30px 0px":"50px 30px 30px 30px"}}>لوازم اسب</h3>
      <table>
          <tbody>
          <tr>
        {fetchedHorseProduct.map((post, index) => (
            
            post.type != "group" ? 

            <td className="post-item" 
            key={index}
            style={{backgroundColor: index===0 ? "white" : "transparent"}}
            onClick={() => {
              navigate("/posts/"+post._id,{ state: {isPublic: true} } );
              }}>

            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={true}
            _id={post._id}
        
          />
            </td>
            
            : null

          ))}

</tr>
</tbody>
          </table>

          <div>
        
        <button 
        style={{marginTop:"50px"}} 
        className="login-button"
        onClick={()=>navigate("/products")}
        >
            مشاهده همه محصولات</button>

            
      
      </div>

      <hr className="solid divider"></hr>




        <h2>آخرین پست ها</h2>


        <table>
          <tbody>
          <tr>
        {fetchedPosts.map((post, index) => (
            
            post.type != "group" ? 

            <td className="post-item" 
            key={index}
            style={{backgroundColor: index===0 ? "white" : "transparent"}}
            onClick={() => {
              navigate("/posts/"+post._id,{ state: {isPublic: true} } );
              }}>

            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={true}
            _id={post._id}
        
          />
            </td>
            
            : null

          ))}

</tr>
</tbody>
          </table>

          <div>
        
            <button 
            style={{marginTop:"50px"}} 
            className="login-button"
            onClick={()=>navigate("/posts")}
            >
                مشاهده همه پست ها</button>
          
          </div>

      </div>

      <div id="apps-section">

        <div>
          <h3 style={{marginBottom:"50px"}}>اپلیکیشن فلیکا را دانلود کنید</h3>
        </div>

        <div style={{display: desktop ? "flex" : "block"}}>

      <span className="app-col1" onClick={()=> setShowInstallDialog(true)}>

        <div><img src={require("../images/app.png")} alt=" فلیکا ، شبکه اجتماعی سوارکاری"/>
        <h6 style={{marginTop:"0px",marginBottom:"30px",fontSize:"16px"}}>فلیکا، برای اسب دوستان</h6>
        </div>


      </span>


        </div>

      </div>

      



    </div>
  );
}

export default HomePage;
