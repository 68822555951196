import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Article from "../components/Article";
import "../Css/Weblog.css";
import WeblogSidebar1 from "../components/WeblogSidebar1";
import WeblogSidebar2 from "../components/WeblogSidebar2";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Helmet } from "react-helmet";
import Pagination from "../components/Pagination";


function Weblog(props)
{

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const [fetchedArticles,setFetchedArticles] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const location = useLocation();
    const {article_id }=useParams();
    const [relatedArticles,setRelatedArticles] = useState([]);
    const [updateArticles,setUpdateArticles] = useState(0);
    const toast = useRef(null);
    const { page } = useParams();
    const [currentPage, setCurrentPage] = useState(parseInt(page) || 1);
    const [totalPages, setTotalPages] = useState(1);
    const articlesPerPage = 10;
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const articleRows = [];
      
      // Group articles into pairs
      for (let i = 0; i < fetchedArticles.length; i += 2) {
        articleRows.push(fetchedArticles.slice(i, i + 2));
      }


      //calculate total pages:
      useEffect( () => {
        
        async function fetchData() {
          await axios.get(process.env.REACT_APP_BACK+"/articles/articles_count", config)
            .then((result) => {
              if (result.status == 200) {
                setTotalPages(Math.ceil(result.data / articlesPerPage));
                
               
              }
            })
        }
        fetchData();
        
      
        
      }, []);



      


      //fetch all articles:
    useEffect(() => {
        async function fetchArticles() {

          
          await axios.get(process.env.REACT_APP_BACK+"/articles/all/"+(currentPage*10), config)
            .then((result) => {
              if (result.status == 200) {
                setFetchedArticles(result.data);
                
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                 navigate("/login")
            });
  
        }

// fetch single article:
        async function fetchSingleArticle() {

            
          await axios.get(process.env.REACT_APP_BACK+"/articles/"+article_id, config)
            .then((result) => {
              if (result.status == 200) {
                const temp = [result.data]
                setFetchedArticles(temp);
                
                return result.data;
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                 navigate("/login")
            });
  
        }

        //fetch related articles:
        async function fetchRelatedArticles()
        {
          axios
          .get(process.env.REACT_APP_BACK+"/articles/all/"+3, config)
          .then((result) => {
              if (result.status == 200) {
              setRelatedArticles(result.data);
              return result.data;

              }
          })
          .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                  navigate("/login")
          });
        }
  

        
        if(!props.singleArticle)
          fetchArticles();
        
        //single article:
        else
        {
          const {article} = location.state || {};
          
          if(!article)
            fetchSingleArticle();
          else
          {
            const temp = [article]
            setFetchedArticles(temp);
          }

          fetchRelatedArticles();
        }
      
    }, [updateArticles,currentPage]);


    //delete article:
    const deleteArticleFromDB = async (_id) => 
      {
        await axios.delete(process.env.REACT_APP_BACK+"/articles/" + _id, config)
        .then(response => 
          {
            if (response.status == 200)
            {
              console.log(response);    
              if(props.singleArticle)
                navigate("/weblog")  ;
              
              toast.current.show({severity: 'info', summary: 'مقاله حذف شد'});
              setUpdateArticles(updateArticles+1);    
              
            }
  
        })
        .catch(error => {
           console.log(error);
           if(error.response.data.statusCode===401)
                navigate("/login")
        });
      };

    
      

    return(

        

        <span id="weblog" >


{/* meta tags: ********************************************************************************************************/}
        
            {props.singleArticle &&
             (

                <Helmet>
                
                <title>{fetchedArticles[0]?.title}</title>
                <meta name="description" content={fetchedArticles[0]?.metaDescription}/>
                <meta name="robots" content="index, follow"/>
                <meta name="author" content={fetchedArticles[0]?.creator?.name || "Unknown"}/>
                <link rel="canonical" href={`${process.env.REACT_APP_SITE_URL}/weblog/${fetchedArticles[0]?._id}`}/>
                <meta property="og:title" content={fetchedArticles[0]?.title}/>
                <meta property="og:description" content={fetchedArticles[0]?.metaDescription}/>
                <meta property="og:image" content={`${process.env.REACT_APP_BACK}/articles/${fetchedArticles[0]?.indexedImage}`}/>
                <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/weblog/${fetchedArticles[0]?._id}`}/>
                <meta property="og:type" content="article"/>
                <meta property="article:published_time" content={fetchedArticles[0]?.createdDate || "2024-07-28"}/>
                <meta property="article:author" content={fetchedArticles[0]?.creator?.name || "Unknown"}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "headline": fetchedArticles[0]?.title,
                    "description": fetchedArticles[0]?.metaDescription,
                    "author": {
                      "@type": "Person",
                      "name": fetchedArticles[0]?.creator?.name || "Unknown"
                    },
                    "publisher": {
                      "@type": "Organization",
                      "name": "Example Publisher",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.example.com/logo.jpg"
                      }
                    },
                    "datePublished": fetchedArticles[0]?.createdDate || "2024-07-28"
                  })}
                </script>
                </Helmet>


          )}
{/**********************************************************************************************************************/}
     
        <Toast ref={toast}></Toast>

            {/* articles: */}
            <span id="weblogMainPanel">

            <div id="weblog-container">

            <span id="weblog-nav" style={{width:"100%",flexDirection:'row',display:"flex" , direction:'rtl'}}>
              <Link to="/"><p>خانه</p></Link>
              <p style={{marginRight:'10px',marginLeft:'10px'}}> >> </p>
              <Link to="/weblog"><p style={{color: !props.singleArticle ? "#af734f" : null}}>وبلاگ</p></Link>
              
              {props.singleArticle &&
              (<span style={{display:'flex'}}>
              <p style={{marginRight:'10px',marginLeft:'10px'}}> >> </p>
              <p style={{marginLeft:'10px',color:'#af734f'}}> {fetchedArticles[0]?.title} </p>
              </span>)}

            </span>

          {!props.singleArticle ?
            <h1 className="mainTitle">وبلاگ</h1>

            : <h1 className="mainTitle">{fetchedArticles[0]?.title}</h1>
          }             
            

            { !props.singleArticle ?
            <p className="subtitle">
              با دنیای اسبان آشنا شوید. هر سوالی درباره نکات اسبداری و رویداد های ورزشی دارید، اینجاست!</p>
            : 
            null
            }
            
            <div style={{ width: '100%' }}>


              {/* multiple articles: */}
              {!props.singleArticle ?

                  articleRows.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ display: desktop ? 'flex' : 'block', width: '100%' , gap: '30px'}}
                    className="multiple_article">
                      {row.map((article, index) => (
                        <div key={index} style={{ width: desktop ? '50%' : "100%", display: 'flex'}}>
                          <Article
                            article={article}
                            singleArticle={false}
                            deleteArticleFromDB={deleteArticleFromDB}
                          />
                        </div>
                      ))}
                    </div>
                  ))

                  : 
                  // single article:
                  fetchedArticles.map((article, index) => (
                    <div key={index} style={{ width: '100%', display: 'flex'}}>

                      
                      <Article
                        article={article}
                        singleArticle={true}
                        deleteArticleFromDB={deleteArticleFromDB}
                      />
                    </div>
                  ))}

                
                </div>


              {props.singleArticle &&
             (<div id="related-articles">
                <h3 >مقاله های مرتبط</h3>

                <div style={{display: desktop ? 'flex' : ' block',gap:'30px'}}>
                {relatedArticles.map((article, index) => (
                

                <Link  key={index} className="other_articles" to={"/weblog/"+article._id} >
                <li style={{marginBottom:'20px'}}>
                    <div>
                        <img 
                            src={`${process.env.REACT_APP_BACK}/articles/${article.indexedImage}`}
                            alt="پستی از فلیکا، شبکه اجتماعی سوارکاری"
                        ></img>
                        <h3>{article.title}</h3>
                        <p>{article.metaDescription+" ..."}</p>
                    </div>
                </li>
                </Link>

            ))}
            </div>

             </div>)}



        
        { !props.singleArticle && (totalPages > 1) &&
              (<div id="paging-section">
              
              <Pagination currentPage={currentPage} totalPages={totalPages} type={"weblog"}/>
              
              </div>)}



            </div>

            </span>

            
            

            {/* single article: */}

            {props.singleArticle ?
                
                <WeblogSidebar1 
                    className="no-scrol"  
                    article_id={article_id ? article_id : props.article?._id}  
                    singleArticle={props.singleArticle}
                    >

                    </WeblogSidebar1>

                    :
                   
            <WeblogSidebar2 className="no-scrol"></WeblogSidebar2>
                    }


        </span>

        
    );
}

export default Weblog;