import "./App.css";
import "/node_modules/primeflex/primeflex.css";
import "primeflex/primeflex.css";
import "primeflex/primeflex.scss";
import { Route, Routes, Navigate, Link, useNavigate } from "react-router-dom";
import MainNavigation from "./components/MainNavigation";
import Footer from "./components/Footer";
import DashboardPage from "./pages/DashboardPage";
import RegisterLogin from "./pages/RegisterLogin";
import { useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFound from "./components/NotFound";
import React, { useEffect, useState } from "react";
import "./Css/ErrorHandler.css";
import Manager from "./components/Manager";
import AllPages from "./components/AllPages";
import AllPosts from "./components/AllPosts";
import axios from "axios";
import AllProducts from "./components/AllProducts";
import AllTags from "./components/AllTags";
import NewArticle from "./components/NewArticle";
import Weblog from "./pages/WeblogPage";
import EditArticle from "./components/EditArticle";
import Products from "./components/Products";
import EditTag from "./components/EditTag";



function App() {
  const location = useLocation();
  const searchType = [0,1,2,3,4,5,6];
  const shopType = [0,1,2];
  const loginType = [0,1];
  const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? true : false;
  const [theme, setTheme] = useState();

  
  
 //get user theme:
useEffect(() =>
{
  const fetchTheme = async () =>
  {
        await axios
          .get(process.env.REACT_APP_BACK+"/settings/theme/"+user._id, config)
          .then((result) => {
            if (result.status == 200) {

              if(result.data)
              setTheme(result.data);
              else
              setTheme("light");
              
              
              console.log("theme: ", result.data);
              
              
            }
          })
          .catch((error) => {
            console.log(error);
          });

    }

    
    if(user._id)
      fetchTheme();
    else
    setTheme("light");
}  
,[]);
  

  

  return (
    <div className={theme+" App"} >

    <ErrorBoundary >


      {location.pathname == "/" || location.pathname==="*" || !online  ? <MainNavigation /> : null}

{theme?
      <Routes>
        <Route exact path="/shop/rider" element={<DashboardPage panelType="shop" shopTab={shopType[0]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/shop/horse" element={<DashboardPage panelType="shop" shopTab={shopType[1]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/shop/horsesForSale" element={<DashboardPage panelType="shop" shopTab={shopType[2]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/settings/account" element={<DashboardPage panelType="settings" settingsTab={0} fetchSettings={false} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/settings/manage-pages" element={<DashboardPage panelType="settings" settingsTab={1} fetchSettings={false} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/settings/notifications" element={<DashboardPage panelType="settings" settingsTab={2} fetchSettings={true} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/:name/about" element={<DashboardPage panelType="profile" profileTab={0} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/:name/posts" element={<DashboardPage panelType="profile" profileTab={1} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/login" element={<RegisterLogin type={loginType[0]}/>} />
        <Route exact path="/register" element={<RegisterLogin type={loginType[1]}/>} />
        <Route exact path="/feed" element={<DashboardPage panelType="feed" setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/new-post" element={<DashboardPage panelType="new-post" setTheme={setTheme} theme={theme} />} />
        <Route exact path="/new-product" element={<DashboardPage panelType="new-product" setTheme={setTheme} theme={theme} />} />
        <Route exact path="/posts/:post_id" element={<DashboardPage panelType="feed" singlePost={true} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search" element={<DashboardPage panelType="search" searchType={searchType[0]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/notification" element={<DashboardPage panelType="notification" setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/notifs" element={<DashboardPage panelType="mobile-notification" setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search/users" element={<DashboardPage panelType="search" searchType={searchType[0]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search/posts" element={<DashboardPage panelType="search" searchType={searchType[5]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search/horses" element={<DashboardPage panelType="search" searchType={searchType[1]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search/farms" element={<DashboardPage panelType="search" searchType={searchType[3]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search/events" element={<DashboardPage panelType="search" searchType={searchType[4]} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/search/shops" element={<DashboardPage panelType="search" searchType={searchType[2]} setTheme={setTheme} theme={theme}/> }/>
        <Route exact path="/search/pages" element={<DashboardPage panelType="search" searchType={searchType[6]} setTheme={setTheme}  theme={theme}/> } />
        <Route exact path="/dashboard" element={<DashboardPage setTheme={setTheme}  theme={theme} />} />
        <Route exact path="/tags/:tag_value" element={ !online || user.role==="manager" ? <Products/> : <DashboardPage panelType="feed" singlePost={false} setTheme={setTheme} theme={theme}/> } />
        <Route path="/tags/:tag_value/page/:page" element={ !online || user.role==="manager"? <Products/> : null } />
        <Route path="/edit-tag/:value" element={ <EditTag/> } />
        <Route exact path="/manager-of-flicka" element={<Manager type="dashboard" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/users" element={<Manager type="users" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/posts" element={<Manager type="posts" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/files" element={<Manager type="files" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/pages" element={<Manager type="pages" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/tags" element={<Manager type="tags"theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/login_as_others" element={<Manager type="logins" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/notifications" element={<Manager type="notifs" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/activities" element={<Manager type="activities" theme={theme}/>}></Route>
        <Route exact path="/manager-of-flicka/weblog" element={<Manager type="weblog" theme={theme}/>}></Route>
        <Route exact path="/new-article" element={<NewArticle/>}></Route>
        <Route exact path="/edit-article" element={<EditArticle/>}></Route>
        <Route exact path="/weblog/:article_id" element={<Weblog singleArticle={true} setTheme={setTheme} theme={theme}/>} />
        <Route exact path="/weblog" element={<Weblog singleArticle={false}></Weblog>}></Route>
        <Route path="/weblog/page/:page" element={<Weblog singleArticle={false}/>} />
        <Route exact path="/pages" element={<AllPages />}></Route>
        <Route exact path="/posts" element={<AllPosts />}></Route>
        <Route exact path="/products" element={<AllProducts />}></Route>
        <Route exact path="/tags" element={<AllTags />}></Route>
        <Route exact path="/404" element={<NotFound />}></Route>
        <Route path='*' element={ <Navigate to="/404" />} />
        
      </Routes>
      :null}


      {location.pathname === "/" || location.pathname==="*" || !online && location.pathname!== "/login" ? <Footer /> : null}

      </ErrorBoundary>

      
    </div>
  );

  



/**
 * NEW: The error boundary has a function component wrapper.
 */
function ErrorBoundary({children}) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [goHome,setGoHome] = useState();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);

  //got to /:
  useEffect(() => {
    if (goHome) {
      setGoHome(false);
      navigate("/");
    }
  }, [goHome]);

  return (
    /**
     * NEW: The class component error boundary is now
     *      a child of the functional component.
     */
    <ErrorBoundaryInner 
    
      hasError={hasError} 
      setHasError={setHasError}
      setGoHome = {setGoHome}
      pathname = {location.pathname}

    >
      {children}
    </ErrorBoundaryInner>
  );
}


}

/**
 * NEW: The class component accepts getters and setters for
 *      the parent functional component's error state.
 */
class ErrorBoundaryInner extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage:""};
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, _previousState) {
    if(!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(_error, _errorInfo) {
    
    this.state.errorMessage = _error.message;
    this.props.setHasError(true);


    
    //if user clicks on tags on home page:
    if(_error.message === "user is null" && !this.props.pathname.includes("tags") )
        this.props.setGoHome(true);

  }

  render() {
    return this.state.hasError
      ? 

      <div id="error-handler">

          {console.log("error: ",this.state.errorMessage)}


            <div id="error-title">            
              <h2>خطای سرور</h2>
              <p>آخ آخ! یه خطایی پیش اومد...</p>
              <span style={{fontSize:"10px"}}>{this.state.errorMessage}</span>
            </div>

            <div id="error-pic"><img src= {require('./images/404.gif')}></img></div>

            <div id="error-button">
              <Link to="/">
              <button>برگرد خونه</button>
              </Link>
              </div>

        </div>


      : this.props.children; 
  }
}





export default App;
