import "../Css/NewPost.css";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../Css/VideoImageUploader.css";
import Chip from "./Chip";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Editor } from 'primereact/editor';
import { InputText } from "primereact/inputtext";
import axios from "axios";


function EditTag()
{

    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`} };
    const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const [tagInfo,setTagInfo] = useState();
    const navigate = useNavigate();
    const { value } = useParams();
    const editorRef = useRef(null);
    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const [htmlContent, setHtmlContent] = useState(""); 
    const [clickedButton, setClickedButton] = useState('');
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);

    //fetch tag's information:
    useEffect(() => {

        async function fetchTagInformation() {
      
      
          
          await axios.get(process.env.REACT_APP_BACK+"/posts/tagsinfo/"+value, config)
                .then((result) => {
                  if (result.status == 200) {
  
                    console.log("result",result.data);
                      setTagInfo(result.data);
                      formik.setFieldValue("content", result.data.content); 
                      formik.setFieldValue("metaDescription", result.data.metaDescription); 
                      formik.setFieldValue("value", result.data.value); 
                      formik.setFieldValue("visits", result.data.visits); 
                      formik.setFieldValue("seoTitle", result.data.seoTitle); 
                            
                    return result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if(error.response.data.statusCode===401)
                    navigate("/login")
                })
      
      
        }
            fetchTagInformation();

    },[]);

    const toggleMode = () => {
        if (isHtmlMode) {
            // Update Formik content with HTML content when switching to WYSIWYG mode
            formik.setFieldValue('content', htmlContent);
        }
        setIsHtmlMode(!isHtmlMode);
    };

    const handleHtmlChange = (e) => {
        setHtmlContent(e.target.value);
        // Update Formik content directly when typing in HTML mode
        formik.setFieldValue('content', e.target.value);
    };

        const colors = [
            'rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 
            'rgb(0, 102, 204)', 'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)', 
            'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)', 'rgb(204, 224, 245)', 
            'rgb(235, 214, 255)', 'rgb(187, 187, 187)', 'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 
            'rgb(255, 255, 102)', 'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)', 
            'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)', 'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 
            'rgb(0, 71, 178)', 'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)', 'rgb(102, 61, 0)', 
            'rgb(102, 102, 0)', 'rgb(0, 55, 0)', 'rgb(0, 41, 102)', 'rgb(61, 20, 102)'
        ];
    
        const createColorOptions = (colors) => colors.map(color => (
            <option key={color} value={color} style={{ backgroundColor: color }}></option>
        ));
    
      
    
    
        //supporting webp:
        const imageHandler = () => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
    
            input.onchange = async () => {
                const file = input.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const quill = editorRef.current.getQuill();
                        const range = quill.getSelection();
                        quill.insertEmbed(range.index, 'image', e.target.result);
                    };
                    reader.readAsDataURL(file);
                }
            };
        };
    
        
    
        const toolbarOptions = (
            <div id="toolbar">
                <select className="ql-header" defaultValue="">
                    <option value="1">h1</option>
                    <option value="2">h2</option>
                    <option value="3">h3</option>
                    <option value="">p</option>
                </select>
                <button className="ql-bold"></button>
                <button className="ql-code"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
                <select className="ql-align">
                    <option value="">Left</option>
                    <option value="center">Center</option>
                    <option value="right">Right</option>
                </select>
                <button className="ql-strike"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-link"></button>
                <button className="ql-image"></button>
                
                <select className="ql-color">
                    {createColorOptions(colors)}
                </select>
                <select className="ql-background">
                    {createColorOptions(colors)}
                </select>
            </div>
        );
    
        const addAltToImages = (content) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const images = doc.querySelectorAll('img');
            images.forEach((img) => {
                // if (!img.hasAttribute('alt')) {
                    img.setAttribute('alt', formik.values.value.replaceAll("_", " ")+"- فلیکا، شبکه اسب دوستان");
                // }
            });
            return doc.body.innerHTML;
        };
    
        const formik = useFormik({
            initialValues: {
                title: tagInfo?.value || '',  // Use empty string if tagInfo is undefined
                content: tagInfo?.content || '',
                metaDescription: tagInfo?.metaDescription || '',
                visits: tagInfo?.visits || ''
            
            },
            validate: (values) => {
                let errors = {};
                if (!values.value) {
                    errors.title = "لطفا نام برچسب را وارد کنید";
                }
                
             
                return errors;
            },
            onSubmit: (values) => {

                    if(!htmlContent)
                    {
                        // Add alt text to images
                        const contentWithAlt = addAltToImages(values.content);
                        values["content"] = contentWithAlt;
                        
                    }
                    else
                    {
                        // Add alt text to images
                        const contentWithAlt = addAltToImages(htmlContent);
                        values["content"] = contentWithAlt;
                    }
              
                submitPost(values);
                return;
            },
        });
    
        const isFormFieldValid = (phone) => !!(formik.touched[phone] && formik.errors[phone]);
        const getFormErrorMessage = (phone) => {
            return (
                isFormFieldValid(phone) && (
                    <p className="p-error" style={{textAlign:'right'}}>{formik.errors[phone]}</p>
                )
            );
        };

        const submitPost = async (values) => {

    
    
            var data = {
                value: values.value,
                content: values.content,
                metaDescription: values.metaDescription,
                visits:parseFloat(value.visits),
                seoTitle:values.seoTitle
            };
    
            
            data = JSON.stringify(data);

            
    
            //creating article in database

            if(clickedButton==="publish")
            {
                axios
                    .patch(process.env.REACT_APP_BACK + "/posts/edit-tag/" + tagInfo._id, data, config)
                    .then((response) => {
                        navigate("/tags/"+tagInfo.value);
                    })
                    .catch((error) => {
                        if (error.response.data.statusCode === 401)
                            navigate("/login");
                    });
            }
            else if(clickedButton === "complete_and_publish")
            {
                axios
                    .post(process.env.REACT_APP_BACK + "/posts/auto_complete/", data, config)
                    .then((response) => {
                        navigate("/tags/"+tagInfo.value);
                    })
                    .catch((error) => {
                        if (error.response.data.statusCode === 401)
                            navigate("/login");
                    });

            }

        }
    
              
          
          
       
          
       
        
      
     
      
    return(

        <div id="new_article">
            <form onSubmit={formik.handleSubmit} className="new-post-form">
                <div>
                    <h2 style={{ marginBottom: '50px' }}>ویرایش برچسب</h2>
                    
                    {getFormErrorMessage("value")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            عنوان برچسب :
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="value"
                            name="value"
                            placeholder="عنوان برچسب ..."
                            value={formik.values.value}
                            onChange={formik.handleChange}
                            maxLength={100}
                        />
                    </div>

                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            عنوان سئو :
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="seoTitle"
                            name="seoTitle"
                            placeholder="عنوان سئو ..."
                            value={formik.values.seoTitle}
                            onChange={formik.handleChange}
                            maxLength={100}
                        />
                    </div>

                  

                    {getFormErrorMessage("metaDescription")}
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            توضیحات متا:
                        </span>
                        <textarea
                            style={{ display: "flex", width: "100%" }}
                            id="metaDescription"
                            name="metaDescription"
                            placeholder="توضیحات متا ..."
                            value={formik.values.metaDescription}
                            onChange={formik.handleChange}
                            maxLength={150}
                        />
                    </div>

                    
                    <div style={{ direction: 'rtl', marginBottom: '30px', alignItems: 'center' }}>
                        <span style={{ display: "flex", width: "fit-content" }}>
                            تعداد بازدیدها:
                        </span>
                        <InputText
                            style={{ display: "flex", width: "100%" }}
                            id="visits"
                            name="visits"
                            placeholder="تعداد بازدیدها..."
                            value={formik.values.visits}
                            onChange={formik.handleChange}
                            maxLength={30}
                        />
                    </div>

                   

                  

                    <span id="content">

                    <div style={{background:"#f8f9fa",textAlign:'right' , marginRight:'10px' , marginBottom:'10px'}} >
                    <a
                        style={{fontSize:"14px"}}
                        onClick={toggleMode}>
                        {isHtmlMode ? "ادیتور متنی" : "کد html"}</a></div>

                    {!isHtmlMode ? (
                        <Editor
                        ref={editorRef}
                        headerTemplate={toolbarOptions}
                        id="content"
                        name="content"
                        value={formik.values.content}
                        onTextChange={(e) => formik.setFieldValue('content', e.htmlValue)}
                        modules={{
                            toolbar: {
                                container: "#toolbar",
                                handlers: {
                                    image: imageHandler
                                }
                            }
                        }}
                        style={{ height: '500px' }}
                        placeholder="محتوای مقاله ..."
                        rows={1}
                        cols={3}
                    />
                    ) : (
                        <textarea
                            id="htmlContent"
                            name="htmlContent"
                            value={htmlContent}
                            onChange={handleHtmlChange}
                            style={{ width: '100%', height: '500px' }}
                        />
                    )}
                      
                   



                        {getFormErrorMessage("content")}
                   

                      
                       
                       
                    </span>
                </div>

                <span style={{ display: "flex", direction: "ltr", marginTop:'50px' }}>

                <Button
                                            type="submit"
                                            style={{background:"transparent" , color:"#af734f", marginRight:'20px'}}
                                            onClick={() => setClickedButton("complete_and_publish")}
                                            >تکمیل خودکار محتوا</Button>
                                
                                    <Button style={{width:'200px' , textAlign:'center' , display:'table'}}  
                                        type="submit"
                                        onClick={() => setClickedButton("publish")}
                                    >ثبت تغییرات</Button>
                                    
                                  
                                        
                                
                            
                                
                            </span>
            </form>
        </div>
    );
}

export default EditTag;