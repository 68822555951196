import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ currentPage, totalPages , type , tagValue}) => {

  const beforeLink = () =>
  {
    if(type==="weblog")
      return `/weblog/page/${currentPage - 1}`;
    else if(type==="tag")
      return `/tags/${tagValue}/page/${currentPage - 1}`;

  }
  const afterLink = () =>
    {
      if(type==="weblog")
        return `/weblog/page/${currentPage + 1}`;
      else if(type==="tag")
        return `/tags/${tagValue}/page/${currentPage + 1}`;
  
    }
    const middleLink = () =>
      {
        if(type==="weblog")
          return `/weblog/page/`;
        else if(type==="tag")
          return `/tags/${tagValue}/page/`;
    
      }

  const getPages = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...');
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="pagination">

      {currentPage > 1 && (
        
        <Link to={beforeLink()} className="pagination-button">
          قبلی
        </Link>
      )}
      {getPages().map((page, index) =>
        typeof page === 'number' ? (
          <Link
            key={index}
            to={middleLink()+`${page}`}
            className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </Link>
        ) : (
          <span key={index} className="pagination-ellipsis">
            {page}
          </span>
        )
      )}
      {currentPage < totalPages && (
        <Link to={afterLink()} className="pagination-button">
          بعدی
        </Link>
      )}
    </div>
  );
};

export default Pagination;
