import { PinDropSharp } from "@material-ui/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import "../Css/SuggestionBox.css";



function SuggestionRow(props)
{

    const user = {_id:props._id, username:props.username, name:props.name , job:props.job , avatarPic:props.image};
    const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const [isFollowed, setIsFollowed] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const url = !props.isPage ? process.env.REACT_APP_BACK+"/notifications/follow/" : process.env.REACT_APP_BACK+"/notifications/follow-page/";
    const navigate = useNavigate();
    const online = onlineUser!==null && onlineUser!==undefined && onlineUser._id!==null && onlineUser._id!==undefined ? true : false;

    const follow = () => {
      

    
        //follow:
        if(!isFollowed)
        {
          const data = JSON.stringify({ sender_id:onlineUser._id,sender_username:onlineUser.username, sender_name:onlineUser.name, sender_avatar:onlineUser.avatarPic, reciever_id:user._id, type:"follow"});
          axios
            .post(url, data, config)
            .then((response) => {
    
            if(response.status == 201)
            {
              console.log(response);
              setIsFollowed(true);
            }
            })
            .catch((error) => {
              console.log("error",error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            });
    
        }
        //unFollow:
        else
        {
          axios
            .delete(url+onlineUser._id+"/"+user._id, config)
            .then((response) => {
    
            if(response.status == 200)
            {
              console.log(response);
              setIsFollowed(false);
            }
            })
            .catch((error) => {
              console.log("error",error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            });
    
        }
      }


      //is user followed?:
      useEffect(() => {
    
        async function isUserFollowed() {
    
          const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/follow/"+onlineUser._id+"/"+user._id, config);
          setIsFollowed(result.data);
          return result.data;
    
        }
        
        isUserFollowed();
      },);
    
      const handleClick = () =>
      {
        if(props.isPost)
          navigate("/posts/"+props._id);

      }

      const addToGroup = async () =>
      {
        if(props.addToGroup)
        {

          //check if user is already added to the group:
          
          const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/follow/"+user._id+"/"+props.group_id, config);
          
          //ok to add:
          if (!result.data)
          {
          const data = JSON.stringify({ sender_id:user._id, sender_avatar:user.avatarPic, reciever_id:props.group_id, type:"follow"});
          axios.post(process.env.REACT_APP_BACK+"/notifications/follow-page", data, config)
          .then(async (response) => {
    
            if(response.status == 201)
            {
              console.log(response);


              //send message to the person who was added:
              var data2 = {sender_id:onlineUser._id,reciever_id:user._id, type:"addToGroup",group_id:props.group_id};
              await axios
              .post(process.env.REACT_APP_BACK+"/notifications/add-to-group", JSON.stringify(data2), config)
              .then((response) => {
  
              if(response.status == 201)
              {
                console.log(response);
              }
              })
              .catch((error) => {
                console.log("error",error);
                if(error.response.data.statusCode===401)
              navigate("/login")
              });



              props.close("کاربر به گروه اضافه شد","success");
            }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            })
          }
          //is added already:
          else
          {
            props.close("کاربر ار قبل عضو بوده","error");
          }

          
        }
      }

      const deleteFromGroup = () =>
      {
        axios.delete(process.env.REACT_APP_BACK+"/notifications/follow-page/"+user._id+"/"+props.group_id, config)
            .then((response) => {
    
            if(response.status == 200)
            {
              console.log(response);
              props.close("کاربر از گروه حذف شد","error");
            }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
              navigate("/login")
            })

      }



    return(
       
        <span className="row" onClick={addToGroup}>
          
          {/* follow & unfollow icon */}
          {(onlineUser._id != user._id) && !props.isPost && ( !(props.group_id!==undefined && props.isUserPage) || (props.group_id===undefined)  ) && online && !props.forSearch ?
                <span 
                className = "col-one" 
                style={{ 
                  background: isFollowed ? "transparent" : "#af734f" ,
                  border: isFollowed ? "1px solid #af734f" : "1px solid #af734f" ,
                  color: isFollowed ? "#af734f" : "#ffffff" ,
                  borderRadius:"30px",
                  padding: "5px 0px",
                  fontSize:"13px",
                  display:"table",
                  margin:"auto"
                }}
                onClick={follow}
                >
                  {isFollowed ? "دنبال میکنم": "دنبال کردن"}
                </span>
          : null}

          {/* delete from group icon (for admin) */}
          {(onlineUser._id != user._id) && props.group_id!==undefined && props.isUserPage && !props.addToGroup && online ?
                <span className = "col-one followed" ><a onClick={deleteFromGroup}>حذف از گروه</a></span>
          : null }

            {/* show description for posts: */}
          {props.isPost && online ?
                <span className = "col-one followed" style={{width: props.type!=="searchbox" ? "50%" : "90%",
                fontSize:"14px", marginRight: props.type==="searchbox" ? "40px" : null}}>{props.description}</span> 
          : null}

          {/* make empty space: */}
          {(onlineUser._id === user._id || props.forSearch) && online ?
                <span className = "col-one followed" style={{width:"50%",fontSize:"14px"}}></span> 
          : null}


                {/* name or description: */}
                {props.type!=="searchbox" && <span className="col-two" style= {{ width: props.isPost ? "50%" : null }}
                onClick= {!props.addToGroup && !props.isPost && props.username!=="undefined" ?  
                () => navigate("/"+props.username+"/about",
                {state:  props.isPage ? { page: user , isPage:true , isUserPage:onlineUser._id===props.pageCreator_id }:{otherUser: user , profileOfOther:true}}) : null}>
                    <div>
                      <span style={{display:"flex",alignItems:"center"}}>
                    <span className="name">{props.name}</span>
                    {props.blueTick ? <img id="blue-tick" src={require("../images/blueTick.png")}></img> : null}
                    </span>
                    { props.job ? <p className="job">{props.job}</p> : null}
                    { props.description ? <p className="job">
                      { props.description.length>100 ? props.description.substring(0,100) +" ..." : props.description}</p> : null}
                    
                    </div>
                    
                </span>
               }
        
                
                <span className="col-three"  style={{cursor:"pointer" }}>


                {/* post: */}
                {props.group_id === undefined && props.isPost ?
                <Link to={"/posts/"+props._id} >
                  {props.fileType==="image" ?
                  <img src={`${process.env.REACT_APP_BACK}/posts/${user.avatarPic}`}
                        style={{borderRadius:"0%",objectFit:"cover"}}></img>
                      :
                  <video src={`${process.env.REACT_APP_BACK}/posts/${user.avatarPic}`}
                      style={{borderRadius:"0%"}}
                      preload="metadata"
                      poster=""
                      ></video>
                }
              
                </Link>
                : null}


                {/* user and page: */}
                {!props.addToGroup && !props.isPost && props.username!=="undefined" ?
                <Link 
                 to={ "/"+props.username+"/about"}
                 state={  props.isPage ? { page: user , isPage:true , isUserPage:onlineUser._id===props.pageCreator_id } : {otherUser: user , profileOfOther:true}}
                
                >
                  <img src={`${process.env.REACT_APP_BACK}/avatars/${user.avatarPic}`}></img>
              
                </Link> 
                : null}

                {/* for deleted user,show goasty avatar */}
                {props.group_id === undefined && !props.isPost && props.username==="undefined" ?
                  <img src={`${process.env.REACT_APP_BACK}/avatars/${user.avatarPic}`}></img>
                : null}


                {/* add users to group: */}
                {props.group_id !== undefined && props.addToGroup ? 
                
                <img src={`${process.env.REACT_APP_BACK}/avatars/${user.avatarPic}`}></img>
                : null}
    
                </span>
                
            </span>
         

    );
}

export default SuggestionRow;