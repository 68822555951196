import "../Css/Page.css";
import "../Css/Profile.css"
import { Dialog } from "primereact/dialog";
import SuggestionRow from "./SuggestionRow";
import { createRef, useEffect, useRef, useState } from "react";
import "../Css/SuggestionBox.css";
import { Divider } from "primereact/divider";
import { TabView, TabPanel } from "primereact/tabview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import "../Css/Feed.css";
import Post from "./Post";
import users from "../data/Users";
import NewPost from "./NewPost";
import "../Css/Register.css";
import SocialForm from "./SocialForm";
import EditPageForm from "./EditPageForm";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import { Toast } from "primereact/toast";
import CropCoverPic from "./CropCoverPic";
import CropAvatarPic from "./CropAvatarPic";
import { ConfirmDialog } from "primereact/confirmdialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";
import Lottie from 'react-lottie';
import fireworks from '../assests/animation_lk9ixjes.json';
import badge from '../assests/Animation-1689760549885.json';



function Page(props)
{

    const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
    const [fetchedPage, setFetchedPage] = useState(props.page);
    const [fetchedFollowers, setFetchedFollowers] = useState([]);
    const [showFollowers, setShowFollowers] = useState(false);
    const [followersHeader, setFollowersHeader] = useState("دنبال شده ها");
    const [showNewPostDialog, setShowNewPostDialog] = useState(false);
    const [showEditAvatar, setShowEditAvatar] = useState(false);
    const [showEditCover, setShowEditCover] = useState(false);
    const [showEditPageDialog, setShowEditPageDialog] = useState(false);
    const [showEditSocialDialog, setShowEditSocialDialog] = useState(false);
    const [catDialog, setCatDialog] = useState(false);
    const [src, setSrc] = useState(null);
    const [pageUpdated, setpageUpdated] = useState(0);
    const [avatarPic, setAvatarPic] = useState("profile-index.png");
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem('userToken'));
    const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
    const pageType={horse:"اسب",event:"رویداد",farm:"مزرعه/باشگاه",shop:"فروشگاه",group:"گروه"}
    const location = useLocation();
    const toast = createRef();
    const [showToast,setShowToast] = useState(0);
    const [isFollowed, setIsFollowed] = useState(props.page.isFollowed);
    const [isForSale, setIsForSale] = useState();
    const [followersCount,setFollowersCount] = useState(0);
    const [cropCover,setCropCover] = useState(false);
    const [cropAvatar,setCropAvatar] = useState(false);
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [showConfirmHorseSale,setShowConfirmHorseSale] = useState(false);
    const [postsLoading, setPostsLoading] = useState(false);
    const [uploadProgress,setUploadProgress] = useState(0);
    const [updatePosts, setUpdatePosts] = useState(true);
    const [fetchedPosts, setFetchedPost] = useState([]);
    const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
    const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? "online" : "offline";
    const [showAddUser,setShowAddUser] = useState();
    const [allUsers,setAllUsers] = useState([]);
    const [lazyLoading, setLazyLoading] = useState(false);
    const addUserRef = useRef();
    const [isUserPage,setIsUserPage] = useState(props.isUserPage);
    const [theme,setTheme] = useState();
    const [showBadge,setShowBadge] = useState(props.showBadge);

    const fireworkslottieOptions = {
      loop: 3,
      autoplay: true,
      animationData: fireworks,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      isClickToPauseDisabled:true 
      
    };


  //get user theme:
useEffect(() =>
{
  const fetchTheme = async () =>
  {
        await axios
          .get(process.env.REACT_APP_BACK+"/settings/theme/"+onlineUser._id, config)
          .then((result) => {
            if (result.status == 200) {
              setTheme(result.data);
              
              
              console.log("theme: ", result.data);
              
              
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });

    }

    
      fetchTheme();
}  
,[]);


    
     //fetching all users (to add to group):
  useEffect(() => {
    async function fetchUsers() {
    
        let heightIndex = 1;
        let offset = 10;
    
        //fetch first group:
        await axios
              .get(process.env.REACT_APP_BACK+"/users/search/"+offset, config)
              .then((result) => {
                if (result.status == 200) {
                  
                  setAllUsers(randomise(result.data));
                  
                  console.log("fetched searched users: ", result.data);
                  return result.data;
                }
              })
              .catch((error) => {
                console.log(error);
                if(error.response.data.statusCode===401)
                  navigate("/login")
              });

        const dialogElement = document.querySelector('.p-dialog-content');
        if (dialogElement) {
          dialogElement.addEventListener('scroll', async function() {


          const { clientHeight, scrollTop, scrollHeight } = dialogElement;

          if (scrollTop + clientHeight >= scrollHeight) {


            // let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
            // let visibleHeight = document.documentElement.clientHeight;
            // let distanceScrolled = scrollPosition + visibleHeight;
        
            // if (distanceScrolled >= 40 * heightIndex) {
      
            //   console.log("heightIndex",heightIndex);
            //   heightIndex += 1;
                
            //   //fetch next:
            //   if(heightIndex === (offset))
            //   {
                offset += 10;
                await setLazyLoading(true);
          
                await axios
                .get(process.env.REACT_APP_BACK+"/users/search/"+offset, config)
                .then((result) => {
                  if (result.status == 200) {
                    setAllUsers(prevArray => [...prevArray, ...randomise(result.data)]);
                    console.log("fetched searched users: ", result.data);
                    return result.data;
                  }
                })
                .catch((error) => {
                  console.log(error);
                  if(error.response.data.statusCode===401)
                    navigate("/login")
                });
                  
              
          
                setLazyLoading(false);
                
              }
            }

          
          
          );
    
        }
      
        
    }

    if(showAddUser && allUsers.length===0)
      fetchUsers();

  }, [showAddUser]);
    
    const follow = () => {

    
      //follow:
      if(!isFollowed)
      {
        const data = JSON.stringify({ sender_id:onlineUser._id, sender_avatar:onlineUser.avatarPic, reciever_id:fetchedPage._id, type:"follow"});
        axios
          .post(process.env.REACT_APP_BACK+"/notifications/follow-page", data, config)
          .then((response) => {
  
          if(response.status == 201)
          {
            console.log(response);
            setIsFollowed(true);
            setFollowersCount(followersCount+1);
          }
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
  
      }
      //unFollow:
      else
      {
        axios
          .delete(process.env.REACT_APP_BACK+"/notifications/follow-page/"+onlineUser._id+"/"+fetchedPage._id, config)
          .then((response) => {
  
          if(response.status == 200)
          {
            console.log(response);
            setIsFollowed(false);
            setFollowersCount(followersCount-1);
          }
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
  
      }
    }


    const sell = () => {

    
      //sell:
      if(!isForSale)
      {
        

        axios.patch(process.env.REACT_APP_BACK+"/pages/put-for-sell/"+ props.page._id + "/" + true, config)
          .then((response) => {
             
          if(response.status == 200)
          {

            console.log(response);
            setIsForSale(true);
          }
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
  
      }
      //unsell:
      else
      {
        axios.patch(process.env.REACT_APP_BACK+"/pages/put-for-sell/"+ props.page._id + "/" + false, config)
          .then((response) => {
  
          if(response.status == 200)
          {
            console.log(response);
            setIsForSale(false);
          }
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
  
      }
    }


    //fetch user followers:
useEffect(() => {
  async function fetchFollowers() {
    
    const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/followers/" + props.page._id, config);
    setFetchedFollowers(result.data);
    
    console.log("fetched followers: ");
    console.log(result);
    return result.data;

    
}

if(showFollowers)
  fetchFollowers();
}
  , [showFollowers]
);



    const coverStyle={
    
      backgroundImage: ` url("${process.env.REACT_APP_BACK}/covers/${fetchedPage["coverPic"]}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
  };
     const deletePage = async (_id) => 
    {

      //delete from followings:
      await axios.delete(process.env.REACT_APP_BACK+"/notifications/delete-from-follower-and-following/" + _id + "/" +fetchedPage.type, config)
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
              navigate("/login")
      });

      //delete page:
      await axios.delete(process.env.REACT_APP_BACK+"/pages/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);

            //now delete all posts of this page:
            axios.delete(process.env.REACT_APP_BACK+"/posts/delete-posts-of-page/" + _id, config);

            props.deletePage();
            navigate("/feed");
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
              navigate("/login")
      });
    }

    const closeSuggestionRow= async (message,messageType) =>
    {
      await setShowAddUser(false);
      await setShowFollowers(false);

      updatePage(true);

      if(message!=="")
          {
          toast.current.show({
            severity: messageType,
            detail: message,
          });
        }
    }

    const closeEditPage = () => {
      setShowEditPageDialog(false);
    };
    const updatePage = () => {
      setpageUpdated(pageUpdated + 1);
    };
    const closeEditSocial = () => {
      setShowEditSocialDialog(false);
    };

    const closeCropPic = () => {
      if(cropAvatar)
        setCropAvatar(false);
      else if(cropCover)
        setCropCover(false);
    
        //update page:
        setpageUpdated(pageUpdated + 1);
    };
  
    const selectImage = (file) => {
      setSrc(URL.createObjectURL(file));
    };
  
    useEffect(
      () => {
        setAvatarPic(fetchedPage["avatarPic"] != null ? fetchedPage["avatarPic"] : "profile-index.png");      
        
      }
      , [fetchedPage]
    );

    useEffect(
      () => {

        if(showToast === 1)
          toast.current.show({
            severity: "success",
            detail: fetchedPage["type"] !== "group" ? "صفحه با موفقیت ساخته شد" : "گروه با موفقیت ساخته شد",
          });
        

      }
      , [showToast]
    );
  

    //fetch page information:
    useEffect(() => {
      async function fetchPage() {
        

        const justCreated = location.state != null ? location.state.justCreated : null;
        if(justCreated)
          setShowToast(showToast+1);
        console.log("justcreted",justCreated);

        const result = await axios.get(process.env.REACT_APP_BACK+"/pages/" + props.page._id, config);

        setFetchedPage(result.data);
        setIsUserPage(result.data.creator_id._id === onlineUser._id ? true : false);
        setIsForSale(result.data.forSale);
        setFollowersCount(result.data.followersCount);

        console.log("fetched page: ",result.data);
        return result.data;
  
      }
      async function isUserFollowed() {

        const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/follow/"+onlineUser._id+"/"+fetchedPage._id, config);
        setIsFollowed(result.data);
        return result.data;
  
      }
    
      setFetchedPage(fetchPage());
      isUserFollowed();
    
      
      
    }, [pageUpdated]);
  

    //setFeedLoading(true):
  useEffect(() => {
  async function feedLoading() {

    setPostsLoading(true);
    
}
    
if(props.profileTab == 1)
  feedLoading(); 

},[]);

    //fetch page's posts:
  useEffect(() => {

  async function fetchPosts() {


    let heightIndex;
    let offset = 10;

    //fetch first group:
    const result = await axios.get(process.env.REACT_APP_BACK+"/posts/posts-of/" + fetchedPage._id+"/"+offset, config)
        .then((result) => {
          if (result.status == 200) {

            setFetchedPost(result.data);
            setPostsLoading(false);
            console.log("fetched posts: ",result.data);
            
            return result.data;
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.response.data.statusCode===401)
              navigate("/login")
        });



        window.addEventListener('scroll', async function() {

          const { clientHeight, scrollTop, scrollHeight } = document.documentElement;

          if (scrollTop + clientHeight >= scrollHeight) {

            
            offset += 10;
            await setLazyLoading(true);
            

            await axios.get(process.env.REACT_APP_BACK+"/posts/posts-of/" + fetchedPage._id+"/"+offset, config)
            .then((result) => {
              if (result.status == 200) {

                setFetchedPost(prevArray => [...prevArray, ... result.data]);
                setPostsLoading(false);
                console.log("fetched posts: ",result.data);

                return result.data;


              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                  navigate("/login")
            });
              
          

            setLazyLoading(false);
            
            

          

        
          }
        });

  }

  if(props.profileTab == 1 && updatePosts)
  {
    
    fetchPosts();
    setUpdatePosts(false);
  }

}, [props,updatePosts]);


    const closeNewPost = (uploadFile,fileType,values,type) => {
      submitPost(uploadFile,fileType,values,type);
      setShowNewPostDialog(false);
      setpageUpdated(pageUpdated + 1);
      setUpdatePosts(true);
      
    };

    const submitPost = (uploadFile,fileType,values,type) =>
  {
    //uploading file
    let formData = new FormData();
    formData.append("file", uploadFile);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };
    
    axios
      .post(process.env.REACT_APP_BACK+"/posts/uploadFile", formData, { c
      ,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
    
      },
      })
      .then((res) => {
        //Now do what you want with the response;

        let fileName = res.data.fileName;


        //save tags:
        var t = values.tags.split(" ");
        for(const index in t)
        {
          //remove empty elements from array:
          if(t[index]==="")
            t.splice(index, 1);

          //remove # from each element:
          if(t[index]!=undefined)
          {
            t[index] = t[index].substring(1);
            t[index].trim();
          }
         

        }

        let data = {
          
          description: values.description,
          category: values.category,
          isProduct:false,
          file:fileName,
          creatorPage: fetchedPage._id,
          groupUser: fetchedPage.type==="group" ? onlineUser._id : undefined,
          type:type,
          fileType:fileType,
          tags:t
        };

        if(fetchedPage.type === "group")
          data["accountType"] = "خصوصی";
        else
          data["accountType"] = "عمومی";

          data = JSON.stringify(data);

        //creating post in database
   

        axios
          .post(process.env.REACT_APP_BACK+"/posts", data, config)
          .then((response) => {
            console.log(response);
            setUpdatePosts(true);
            
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
      })
      .catch((error) =>
      {
        if(error.response.data.statusCode===401)
        navigate("/login")
      });

  }


  
    const tabHandler = (e) => {
      if (e == 0)
        navigate("/" + fetchedPage["username"] + "/about", 
          { state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } });
      if (e == 1)
        navigate("/" + fetchedPage["username"] + "/posts",
          { state: {page: fetchedPage, isPage: true , isUserPage:isUserPage }});
    };


    const askFromSeller = (message) =>
    {
      if(fetchedPage.creator_id !==null && fetchedPage.creator_id !== undefined)
      {
          navigate("/"+fetchedPage.creator_id.username+"/about",
              {state:{otherUser: {_id:fetchedPage.creator_id._id, username:fetchedPage.creator_id.username, name:fetchedPage.creator_id.name , job:fetchedPage.creator_id.job , avatarPic:fetchedPage.creator_id.avatarPic} 
              , profileOfOther:fetchedPage.creator_id._id!==onlineUser._id,message:message}});
        


      }
    }

      
  const randomise = (r) =>
  {
         //Fisher-yates shuffle algorithm:
         for(let i=r.length-1; i>0; i--)
         {
            const j = Math.floor(Math.random()*(i+1));
            [r[i],r[j]] = [r[j],r[i]];
         }

         return r;
  }

    
  
  
  

    return (

        <div id="user-profile" 
        className={online + " page-profile"}
        style={{
          width:"94%"
          
      }}>

      
    

      {/* meta tags: ********************************************************************************************************/}
        
      

                <Helmet>
     
                <title>{fetchedPage?.name}</title>
                <meta name="description" content={fetchedPage?.description?.substring(0,150)}/>
                <meta name="robots" content="index, follow"/>
                <meta name="author" content={fetchedPage?.creatorName || "Unknown"}/>
                <link rel="canonical" href={`${process.env.SITE_URL}/${fetchedPage?.username}/about`}/>
                <meta property="og:title" content={fetchedPage?.name}/>
                <meta property="og:description" content={fetchedPage?.description?.substring(0,150)}/>
                <meta property="og:image" content={`${process.env.REACT_APP_BACK}/avatars/${fetchedPage?.avatarPic}`}/>
                <meta property="og:url" content={`${process.env.SITE_URL}/${fetchedPage?.username}/about`}/>
                <meta property="og:type" content="profile"/>
                <meta property="article:published_time" content={fetchedPage?.regDate || "2024-07-28"}/>
                <meta property="article:author" content={fetchedPage?.creatorName || "Unknown"}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "headline": fetchedPage?.name,
                    "description": fetchedPage?.description?.substring(0,150),
                    "author": {
                      "@type": "Person",
                      "name": fetchedPage?.creatorName || "Unknown"
                    },
                    "publisher": {
                      "@type": "Organization",
                      "name": "Example Publisher",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.example.com/logo.jpg"
                      }
                    },
                    "datePublished": fetchedPage?.regDate || "2024-07-28"
                  })}
                </script>
                </Helmet>


          
{/**********************************************************************************************************************/}
        

      <Toast ref={toast} life="3000" position="center center" />



        {/* show badge animation */}
        <div onClick={()=> setShowBadge(false)}>
        {showBadge && 
        <div style={{position:"absolute" , height:"100vh",zIndex:"1000"}} >
        <Lottie     options={fireworkslottieOptions}  />
        
          <div className="popup">
            <div className="popup-content">
              <img src={require("../images/rosette.png")} alt="Popup Image" className="zoom-in" style={{width:"50%",height:"auto"}}/>
            </div>
          </div>
        </div>
        }
        </div>

      <Dialog
      id="add-user-to-group"
      className={theme}
      header="انتخاب عضو جدید"
      visible={showAddUser}
      onHide={()=>setShowAddUser(false)}
      ref={addUserRef}
      style={{ width: !desktop ? "90%" : "30%"}}
      dismissableMask={true}
      >

      <div>
      {allUsers.map((post, index) => {
                  return (
                    <div className={"user" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        <SuggestionRow
                          key={index}
                          username={post.username}
                          name={post.name}
                          job={post.job}
                          image={post.avatarPic}
                          _id={post._id}
                          isPage={false}
                          group_id={fetchedPage._id}
                          isUserPage={isUserPage}
                          close = {closeSuggestionRow}
                          addToGroup={true}
                          forSearch={true}
                        ></SuggestionRow>
                      </div>
                    </div>
                  );
                })}


              {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}

      </div>

      </Dialog>
      

      <Dialog
        id="crop-profile"
        className={theme}
        header="انتخاب عکس پروفایل"
        visible={cropAvatar}
        onHide={() => setCropAvatar(false)}
      >
        <CropAvatarPic isPage={true} avatarPic={fetchedPage.avatarPic} user_id={fetchedPage._id} close={closeCropPic}/>
      </Dialog>

      
      <Dialog
        id="crop-profile"
        className={theme}
        header="انتخاب عکس کاور"
        visible={cropCover}
        onHide={() => setCropCover(false)}
      >
        <CropCoverPic isPage={true} coverPic={fetchedPage.coverPic} user_id={fetchedPage._id} close={closeCropPic}/>
      </Dialog>

      
      <Dialog
        id="edit-profile-form"
        className={theme}
        header="ویرایش صفحه"
        visible={showEditPageDialog}
        onHide={() => setShowEditPageDialog(false)}
        dismissable="false"
        style={{ width: !desktop ? "90%" : "30%"}}

      >
        <EditPageForm close={closeEditPage} page={fetchedPage} type={fetchedPage.type} updatePage={updatePage} />
      </Dialog>

      <Dialog
        id="edit-profile-form"
        className={theme}
        header="راه های ارتباطی"
        visible={showEditSocialDialog}
        onHide={() => setShowEditSocialDialog(false)}
        
        dismissable="false"
      >
        <SocialForm isPage={true} user={fetchedPage} close={closeEditSocial} updatePage={updatePage} />
      </Dialog>
      
      <ConfirmDialog 
            className={theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این صفحه مطمئنید؟ (تمام پست های این صفحه نیز حذف خواهند شد)"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deletePage(fetchedPage._id)} 
            reject={() => setShowConfirmDelete(false)} />

          <ConfirmDialog 
            className={theme}
            visible={showConfirmHorseSale} 
            onHide={() => setShowConfirmHorseSale(false)} 
            message={!isForSale ? "آیا از به فروش گذاشتن اسبتان مطمئنید؟" : "آیا از فروش اسبتان انصراف می دهید؟"}
            header={!isForSale ? "تایید فروش" : "انصراف از فروش"}
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => sell()} 
            reject={() => setShowConfirmHorseSale(false)} />
            
      <div
        id="profile-cover"
        onMouseEnter={() => {isUserPage ? setShowEditCover(true) : setShowEditCover(false)}}
        onMouseLeave={() => {isUserPage ? setShowEditCover(false) : setShowEditCover(false) }}
        style={coverStyle}
      >
        <i
          className="pi pi-camera"
          style={{ display: (online==="online" && isUserPage && (showEditCover || !desktop)) ? "flex" : "none" }}
          onClick={() => setCropCover(true)}
        ></i>
      </div>
      <div id="profile-nav">
        <span id="p-row1">
          <span className="p-col1" style={{display:"flex"}}>

            <span style={{display:"flex",width:"fit-content"}}>
            <div
              className="p-avatarPic"
               onMouseEnter={() => { isUserPage ? setShowEditAvatar(true) : setShowEditAvatar(false);}}
              onMouseLeave={() => { isUserPage ? setShowEditAvatar(false) : setShowEditAvatar(false)}}
              onClick= {isUserPage ? () => setCropAvatar(true) : null}
              style={{
                background: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.15)",
                marginTop: "-70px",
                width: "140px",
                height: "140px",
              }}
            >
              <div
                id="edit-profile-pic"
                style={{
                  display: (online==="online" && isUserPage && (showEditAvatar || !desktop)) ? "block" : "none",
                  background: "transparent",
                  borderRadius: "50%",
                  marginTop: "0px",
                  width: "140px",
                  height: "140px",
                  position: "absolute",
                  cursor: "pointer",
                }}
              >
                <i
                  className="pi pi-camera"
                  style={{
                    marginTop: "40%",
                    fontSize: "25px",
                    color: "#ffffff",
                  }}
                ></i>
              </div>

              <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`} alt={`فلیکا - ${fetchedPage.name}`}></img>
            </div>
            </span>


            <span style={{display:"flex",width:"fit-content"}}>
            <div style={{ textAlign: "right", margin: "-20px 10px 0px 0px" }}>

            <span style={{display:"flex",alignItems:"center",direction:"rtl",marginTop:"25px"}}>
                    <h1 style={{ marginBottom: "0px",marginTop:"0px" }}>{fetchedPage.name}</h1>
                    {fetchedPage.blueTick ? <img id="blue-tick" src={require("../images/blueTick.png")} style={{marginTop:"0px"}}></img> : null}
            </span>

              
    
            </div>
            </span>

          </span>
          <span className="p-col2">

          { isUserPage ?

            <button onClick={() => setShowConfirmDelete(true)}>
              <i className="pi pi-trash" style={{ fontSize: "22px" }}></i>
            </button>

            :null}

          { isUserPage ?
        
            <button onClick={() => setShowEditPageDialog(true)}>
              <i className="pi pi-pencil" style={{ fontSize: "22px" }}></i>
            </button>
            
            :null}

            
          </span>
        </span>
        <span id="p-row2">
          <span className="p-col1">
            <button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                setShowFollowers(true);
                setFollowersHeader( fetchedPage["type"]!=="group" ? "دنبال کننده ها" : "اعضا");
              }}
            >
              {followersCount} {fetchedPage["type"] === "group" ? "عضو" : "دنبال کننده"}
              
            </button>

            {/* follow / unfollow */}

            { online==="online" && fetchedPage.type==="group" && isFollowed ?
            <div id="follow-page-button" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

              <i className="pi pi-user-plus"
               style={{color:"#af734f", cursor:"pointer",fontSize:"25px", marginLeft:"10px"}}
               onClick={() => setShowAddUser(true)}
               ></i>
            </div>
            : null }

            { online==="online" && fetchedPage["type"] === "group" && !isUserPage ?
            <div id="follow-page-button" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

              <a 
                 style={{ 
                  background: isFollowed ? "transparent" : "#af734f" ,
                  border: isFollowed ? "1px solid #af734f" : "1px solid #af734f" ,
                  color: isFollowed ? "#af734f" : "#ffffff" ,
                  borderRadius:"30px",
                  padding: "10px 20px 10px 20px",
                  fontSize: "14px"
                }}
                onClick={follow}>
                
                {isFollowed && fetchedPage["type"] === "group"  ? "ترک گروه": null}
                {!isFollowed && fetchedPage["type"] === "group"  ? "درخواست عضویت": null}
                </a>
            </div>
            : null }



          { online==="online" && fetchedPage["type"] !== "group" ?
            <div id="follow-page-button" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

              <a 
                 style={{ 
                  background: isFollowed ? "transparent" : "#af734f" ,
                  border: isFollowed ? "1px solid #af734f" : "1px solid #af734f" ,
                  color: isFollowed ? "#af734f" : "#ffffff" ,
                  borderRadius:"30px",
                  padding: "10px 20px 10px 20px",
                  fontSize: "14px"
                }}
                onClick={follow}>
                
                {isFollowed && fetchedPage["type"] !== "group"  ? "دنبال میکنم": null}
                {!isFollowed && fetchedPage["type"] !== "group"  ? "دنبال کردن": null}
      
                </a>
            </div>
            : null }

             {/* Message */}
             { online==="online" && fetchedPage["type"] === "shop" ?
             <div className="p-message" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

            <a 
            // className = {"follow-button followed"}
            style={{ 
              background: "transparent" ,
              border:"1px solid #af734f",
              color: "#af734f",
              borderRadius:"30px",
              padding: "10px 10px 10px 10px",
              fontSize:"15px"
            }}
              onClick={() =>
                navigate("/"+fetchedPage.creator_id.username+"/about",
                {
                state:{
                    otherUser: {_id:fetchedPage.creator_id._id, username:fetchedPage.creator_id.username, name:fetchedPage.creator_id.name , job:fetchedPage.creator_id.job , avatarPic:fetchedPage.creator_id.avatarPic},
                    profileOfOther:true, 
                    message:true, 
                    isPage:false  
                }})}

            >
              ارسال پیام
              </a>
            </div>
            : null}

          { online==="online" && fetchedPage["type"] === "group" && isUserPage ?
            <div id="follow-page-button" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

              <a 
                 style={{ 
                  background: isFollowed ? "transparent" : "#af734f" ,
                  border: isFollowed ? "1px solid #af734f" : "1px solid #af734f" ,
                  color: isFollowed ? "#af734f" : "#ffffff" ,
                  borderRadius:"30px",
                  padding: "10px 20px 10px 20px",
                  fontSize: "14px",
                }}>
                ادمین گروه
                </a>
            </div>
            : null }


          { online==="online" &&  isUserPage && fetchedPage.type==="horse"?
            <div id="follow-page-button" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

              <a 
              
                 style={{ 
                  background: isForSale ? "transparent" : "#af734f" ,
                  border: isForSale ? "1px solid #af734f" : "1px solid #af734f" ,
                  color: isForSale ? "#af734f" : "#ffffff" ,
                  borderRadius:"30px",
                  padding: "10px 20px 10px 20px",
                  fontSize: "14px"
                }}
                onClick={()=>setShowConfirmHorseSale(true)}>
                
                {!isForSale ? "بذار برای فروش": "انصراف از فروش"}
                </a>
            </div>
            : null }

        { online==="online" &&  !isUserPage && fetchedPage.type==="horse" && fetchedPage.forSale?
        <div id="follow-page-button" style={{ textAlign: "right", margin: "0px 10px 0px 0px" }}>

        <a 
        
           style={{ 
            background: "#af734f" ,
            border: "1px solid #af734f" ,
            color: "#ffffff" ,
            borderRadius:"30px",
            padding: "10px 20px 10px 20px",
            fontSize: "14px"
          }}
            onClick={()=>askFromSeller(true)}
            
            >
          ارتباط با فروشنده
          </a>
      </div>
        :null}




            
         
          </span>
          <Dialog
            id="suggestion-box"
            className={theme}
            visible={showFollowers}
            header={followersHeader}
            style={{ width: !desktop ? "90%" : "30%"}}
            onHide={() => setShowFollowers(false)}
            online={online==="online" ? true : false }
            dismissableMask={true}
          >
            <div>
              <Divider style={{ marginTop: "0px" }} />
            </div>

            {fetchedFollowers.map((follower, index) => (


            
            follower!==null && <SuggestionRow
              key={index}
              name={follower.name}
              job={follower.job}
              image={follower.avatarPic}
              username={follower.username}
              _id={follower._id}
              isPage={follower.type == undefined ? false : true}
              online={online==="online" ? true : false }
              group_id={ fetchedPage["type"] === "group" ? fetchedPage._id : undefined}
              isUserPage={isUserPage}
              close = {closeSuggestionRow}
              addToGroup={false}
              isPost={false}
            ></SuggestionRow>

            

          ))}
          </Dialog>

          

          
        </span>
      </div>

      <div id="profile-content">
        <TabView
          id="profile-tab"
          style={{ direction: "rtl" }}
          activeIndex={props.profileTab}
          onTabChange={(e) => tabHandler(e.index)}
        >
          <TabPanel header="درباره">
            <div id="about-panel">
              <span id="tab1-col2" style={{display : !desktop ? "contents" : null}}>
                <div className="info-box" style={ !desktop ? { width:"80%", display: "block", direction: "rtl", margin: "0% 10% 5% 10%" } : null}>
                  <span className="info-box-header">
                    <span className="info-header-col1">
                      
                      <h6>درباره</h6>
                    </span>

                    { isUserPage ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditPageDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">
                  <span className="about-box-content"  style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-info-circle"></i>{fetchedPage.name} {" , "} {pageType[fetchedPage.type]} {" "} {fetchedPage.type == "group" ? fetchedPage.groupMode : null}</span>
                  { fetchedPage["country"] != null && fetchedPage["country"] != ""?
                  <span className="about-box-content"  style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>{fetchedPage.country} , {fetchedPage.city}</span>
                  : null }
                   { fetchedPage["age"] != null && fetchedPage["age"] != ""?
                  <span className="about-box-content"  style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>{"سال تولد: "+fetchedPage.age}</span>
                  
                  : null }
                  { fetchedPage["gender"] != null && fetchedPage["gender"] != ""?
                  <span className="about-box-content"  style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>{"جنسیت: "+fetchedPage.gender}</span>                  
                  : null }
                   { fetchedPage["race"] != null && fetchedPage["race"] != ""?
                  <span className="about-box-content"  style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>{"نژاد: "+fetchedPage.race}</span>                  
                  : null }
                  
                  { fetchedPage["mother"] != null && fetchedPage["mother"] != ""?
                  
                  <Link 
                  className="about-box-content" 
                  to={"/"+fetchedPage.mother.username+"/about"}
                  state={{  page: fetchedPage.mother , isPage:true , isUserPage:onlineUser._id===fetchedPage.mother.creator_id }}> 
                  <span style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , alignItems:"center",cursor:"pointer", color:"#252525"}}
                  onClick={() => navigate("/"+fetchedPage.mother.username+"/about",{state:{ page: fetchedPage.mother, isPage:true , isUserPage:onlineUser._id===fetchedPage.mother.pageCreator_id }})}
                  >
                  <i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>
                  {"مادر:  "}
                  <img className="father-mother-pic" src={`${process.env.REACT_APP_BACK}/avatars/${fetchedPage.mother.avatarPic}`}></img>
                  {fetchedPage.mother.name}
                  </span>
                  </Link>                  
                  : null }


                  { fetchedPage["father"] != null && fetchedPage["father"] != ""?
                 <Link 
                 className="about-box-content" 
                 to={"/"+fetchedPage.father.username+"/about"}
                 state={{  page: fetchedPage.father , isPage:true , isUserPage:onlineUser._id===fetchedPage.father.creator_id }}>
                  <span style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , alignItems:"center", cursor:"pointer" , color:"#252525"}}>
                    <i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>
                  {"پدر:  "}
                  <img className="father-mother-pic" src={`${process.env.REACT_APP_BACK}/avatars/${fetchedPage.father.avatarPic}`}></img>
                  {fetchedPage.father.name}
                  </span>
                  </Link>       
                  : null }


                  { fetchedPage["farm"] != null && fetchedPage["farm"] != ""?
                  <span className="about-box-content"  style={{marginRight:"20px",marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>{"باشگاه: "+fetchedPage.farm}</span>                  
                  : null }
                  { fetchedPage["activity"] != null && fetchedPage["activity"] != ""?
                  <span className="about-box-content"  style={{marginRight:"20px", marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-minus" style={{fontSize: "0.7rem",marginTop: "5px"}}></i>{"زمینه فعالیت: "+fetchedPage.activity}</span>                  
                  : null }
                

                    {/* { (fetchedPage["awards"] == null || fetchedPage["awards"] == "") && !props.profileOfOther ?
                    <span
                      onClick={() => setShowEditProfileDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن جایزه ها
                    </span>
                    : null } */}
                    <div>
                    <span>
                      <i className="pi pi-calendar"></i>
                      تاریخ ایجاد: 
                      {" "+new Date(fetchedPage["regDate"]).toLocaleDateString('fa-IR')}
                    </span>
                    </div>
                    <div style={{marginTop:"10px"}}>
                    <span style={{cursor:"pointer"}} 
                    onClick={()=>askFromSeller(false)}
                    >
                      <i className="pi pi-user"></i>
                      <span>
                        {fetchedPage["type"] === "group" ? "سازنده گروه: " : "سازنده صفحه: "}
                      </span>
                     <span style={{color:"rgb(175, 115, 79)"}}>{" "+fetchedPage["creatorName"]}</span>
                    
                    </span>
                    </div>
                  </div>
                </div>



{ fetchedPage.type != "horse" && fetchedPage.type != "group" ?



                <div className="info-box" style={ !desktop ? { width:"80%", display: "block", direction: "rtl", margin: "0% 10% 5% 10%" } : null}>
                  <span className="info-box-header">
                    <span
                      className="info-header-col1"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <h6>راه های ارتباطی</h6>
                    </span>

                    { isUserPage ?
                    <span
                      className="info-header-col2"
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">

                  
                  <div>
                  
                  {fetchedPage.tell1 ? <span className="about-box-content" style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i> تلفن ثابت: {fetchedPage.tell1}</span> : null }
                  {fetchedPage.tell2 ? <span className="about-box-content" style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i>تلفن ثابت 2 : {fetchedPage.tell2}</span> : null }
                    {fetchedPage.phone ? <span className="about-box-content" style={{marginBottom:"10px" , display:"flex" , color:"#252525"}}><i className="pi pi-phone"></i>شماره همراه: {fetchedPage.phone}</span> : null }
                    {fetchedPage.email ? <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-envelope"></i>ایمیل: {fetchedPage.email}</span> : null}
                    {fetchedPage.address ? <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-map-marker"></i>آدرس: {fetchedPage.address}</span> : null}
                  </div>
                  

                    { fetchedPage.website ? <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}><i className="pi pi-globe"></i>وبسایت: { fetchedPage.website}</span> : null}
                    
                    { (fetchedPage.instagram != null && fetchedPage.instagram != "") || (fetchedPage.telegram != null && fetchedPage.telegram != "") || (fetchedPage.whatsapp != null && fetchedPage.whatsapp != "") || (fetchedPage.facebook != null && fetchedPage.facebook != "") || (fetchedPage.twitter != null && fetchedPage.twitter != "") || (fetchedPage.linkedin != null && fetchedPage.linkedin != "") || (fetchedPage.youtube != null && fetchedPage.youtube != "") ?
                    <span className="about-box-content" style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>شبکه های اجتماعی:</span> : null }
                    <span style={{marginBottom:"10px" , display:"flex", color:"#252525"}}>
                      { fetchedPage.instagram != null && fetchedPage.instagram != "" ?<span className="social-icon"><a href={"https://instagram.com/"+fetchedPage.instagram}><i className="pi pi-instagram"></i></a></span> : null }
                      { fetchedPage.telegram != null  && fetchedPage.telegram != ""? <span className="social-icon"><a href={fetchedPage.telegram}><i className="pi pi-telegram"></i></a></span> : null }
                      { fetchedPage.whatsapp != null && fetchedPage.whatsapp != "" ? <span className="social-icon"><a href={"https://api.whatsapp.com/send?phone="+fetchedPage.whatsapp}><i className="pi pi-whatsapp"></i></a></span> : null }
                      { fetchedPage.facebook != null && fetchedPage.facebook != "" ? <span className="social-icon"><a href={"https://facebook.com/"+fetchedPage.facebook}><i className="pi pi-facebook"></i></a></span> : null }
                      { fetchedPage.twitter != null && fetchedPage.twitter != "" ? <span className="social-icon"><a href={"https://twitter.com/"+fetchedPage.twitter}><i className="pi pi-twitter"></i></a></span> : null }
                      { fetchedPage.linkedin != null && fetchedPage.linkedin != "" ? <span className="social-icon"><a href={"https://linkedin.com/"+fetchedPage.linkedin}><i className="pi pi-linkedin"></i></a></span> : null }
                      { fetchedPage.youtube != null && fetchedPage.youtube != "" ? <span className="social-icon"><a href={"https://youtube.com/"+fetchedPage.youtube}><i className="pi pi-youtube"></i></a></span> : null }
                      </span>

                      { !props.profileOfOther ?
                    <span
                    id="addConnection"
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "10px",
                        marginTop:"10px",
                        fontSize:"13px"
                      }}
                      onClick={() => setShowEditSocialDialog(true)}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن راه ارتباطی
                    </span>

                    :null }

                  </div>
                </div> 

: null}








              </span>

              <span id="tab1-col1">
                {fetchedPage["description"] == null || fetchedPage["description"]== "" ? (
                  <div style={{display: "table", margin: "auto"}}>
                    <img src={require("../images/intro-index.png")}></img>
                    <p style={{color:"#25252580"}}>اطلاعات تکمیلی این صفحه هنوز ثبت نشده!</p>
                  </div>
                ) : null}



                {fetchedPage["description"] != null && fetchedPage["description"] != ""?
                  <div className="about-box" id="page-description">
                    <div><span style={{ display: "flex", direction: "rtl", margin: "10px 20px 10px 0px" }}><i className="pi pi-id-card"></i><h6>توضیحات</h6></span></div>
                    <div className="about-box-content"><p>{fetchedPage["description"]}</p></div>
                  </div>
                  :
                  null

                }
                {
                  fetchedPage["awards"] != null && fetchedPage["awards"] != ""?

                  <div className="about-box">
                  <div><span style={{ display: "flex", direction: "rtl", margin: "10px 20px 10px 0px" }}><i className="pi pi-star"></i><h6>جایزه ها</h6></span></div>
                  <div><p>{fetchedPage["awards"]}</p></div>
                </div>

                    : null
                }





              </span>
            </div>
          </TabPanel>
          <TabPanel header="پست ها">
            <div id="posts-panel">
              <span id="tab1-col2">
                <div className="info-box">
                  <span className="info-box-header">
                    <span className="info-header-col1">
                      <h6>دسته بندی ها</h6>
                    </span>

                    { isUserPage ?
                    <span
                      className="info-header-col2"
                      onClick={() => setCatDialog(true)}
                    >
                      <i className="pi pi-pencil"></i>
                    </span>
                    : null }
                  </span>
                  <div className="info-box-content">
                    <span>
                      <i className="pi pi-folder"></i>
                      همه دسته ها
                    </span>

                    { isUserPage ?
                    <span
                      onClick={() => setCatDialog(true)}
                      style={{
                        color: "#af734f",
                        cursor: "pointer",
                        display: "flex",
                        margin: "10px 0px 10px 0px",
                      }}
                    >
                      <i className="pi pi-plus-circle"></i>
                      افزودن دسته جدید
                    </span>
                    : null }
                  </div>
                </div>
              </span>

              <span id="tab1-col1">
                <div id="feed">
                  <a>


                    { isUserPage || (fetchedPage["type"] === "group" && isFollowed) ?

                    <span id="create-post">
                      <span id="feed-col1">
                        <Button
                           onClick={ !desktop ?  () =>
                            {
                              if(fetchedPage.type === "shop")
                                  navigate("/new-product",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                              else
                                  navigate("/new-post",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                            }

                            :
                            () => setShowNewPostDialog(true)
  
                            }
                          tooltip="عکس"
                          tooltipOptions={{
                            className: "create-post-tooltip",
                            position: "top",
                          }}
                        >
                          <i className="pi pi-image"></i>
                        </Button>
                        <Button
                           onClick={ !desktop ?  () =>
                            {
                              if(fetchedPage.type === "shop")
                                  navigate("/new-product",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                              else
                                  navigate("/new-post",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                            }

                            :
                            () => setShowNewPostDialog(true)
  
                            }
                          tooltip="ویدیو"
                          tooltipOptions={{
                            className: "create-post-tooltip",
                            position: "top",
                          }}
                        >
                          <i className="pi pi-video"></i>
                        </Button>
                      </span>

                      <span
                         onClick={ !desktop ?  () =>
                          {
                            if(fetchedPage.type === "shop")
                                navigate("/new-product",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                            else
                                navigate("/new-post",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                          }

                          :
                          () => setShowNewPostDialog(true)

                          }
                        id="feed-col2"
                      >
                        انتشار پست جدید ...
                      </span>
                      <span
                        onClick={ !desktop ?  () =>
                          {
                            if(fetchedPage.type === "shop")
                                navigate("/new-product",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                            else
                                navigate("/new-post",{ state: {page: fetchedPage, isPage: true , isUserPage:isUserPage } })
                          }

                          :
                          () => setShowNewPostDialog(true)

                          }
                        id="feed-col3"
                      >
                        <img src={`${process.env.REACT_APP_BACK}/avatars/${avatarPic}`}></img>
                
                      </span>
                    </span>


                    : null }

                    <Dialog
                      className={theme+" new-post-dialog"}
                      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                      position="top"
                      header={fetchedPage.type === "shop" ? "محصول جدید" : "پست جدید"}
                      visible={showNewPostDialog}
                      style={{ width: "40vw" }}
                      onHide={() => setShowNewPostDialog(false)}
                    >
                      <NewPost theme={theme} type= {fetchedPage.type === "shop" ? "product" : "normal"} user={fetchedPage} close={closeNewPost} />
                    </Dialog>
                  </a>


                  <div id="upload-progress" style={{display: (uploadProgress===100 || uploadProgress===0) ? "none" : "block"}}>
                  <span>
                <div className="row1"><img src={process.env.REACT_APP_BACK+`/avatars/${avatarPic}`}></img></div>
                <div className="row2"><LinearProgress variant="determinate" value={uploadProgress} /></div>
                
                </span>
                </div>

                          
                     {/* load animation */}
          {postsLoading ? 
            <div className="spinner"></div>
        : null}

{fetchedPosts.map((post, index) => (
            
            
            <Post
                    isPage={true}
                    page={fetchedPage}
                    key={index}
                    creatorUsername={fetchedPage.username}
                    creator_id={post.creatorPage._id}
                    creatorName={post.creatorPage.name}
                    creatorAvatar={post.creatorPage.avatarPic}
                    file={post.file}
                    fileType={post.fileType}
                    category={post.category}
                    tags={post.tags}
                    taggedUsers={post.taggedUsers}
                    date={post.date}
                    description={post.description}
                    likesCount={post.likesCount}
                    commentsCount={post.commentsCount}
                    postOfOther={post.creatorPage.creator_id !== onlineUser._id && post.groupUser!==onlineUser._id}
                    _id={post._id}
                    updatePost={closeNewPost}
                    //used for like & save:
                    userAvatar={onlineUser.avatarPic}
                    user_id={onlineUser._id}
                    user_name={onlineUser.name}
                    user_username={onlineUser.username}
                    theme={theme}
                    blueTick={post.creatorPage.blueTick}
                  />
            

          ))}

                  
                  <br />
                </div>
              </span>
            </div>
          </TabPanel>
        </TabView>
      </div>

        </div>
    );
}

export default Page;
