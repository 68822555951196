import "../Css/Feed.css";
import { Button } from "primereact/button";
import Post from "./Post";
import { useLocation, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import SuggestionRow from "./SuggestionRow";
import { Dialog } from "primereact/dialog";
import NewPost from "./NewPost";
import axios from "axios";
import { useParams } from 'react-router-dom';
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";
import Lottie from 'react-lottie';
import fireworks from '../assests/animation_lk9ixjes.json';






function Feed(props) {

  const user = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const location = useLocation();
  const navigate = useNavigate();
  const [showNewPostDialog, setShowNewPostDialog] = useState(false);
  const [showNewProductDialog, setShowNewProductDialog] = useState(false);
  const avatarPic = user["avatarPic"];
  const [allUsers,setAllUsers] = useState([]);
  const [allHorses,setAllHorses] = useState([]);
  const [allFarms,setAllFarms] = useState([]);
  const [allEvents,setAllEvents] = useState([]);
  const [allShops,setAllShops] = useState([]);
  const [fetchedPosts, setFetchedPost] = useState([]);
  const [savedPosts, setSavedPost] = useState([]);
  const [updatePosts, setUpdatePosts] = useState(true);
  const [updateSavedPosts, setUpdateSavedPosts] = useState(true);
  const [updateSinglePost, setUpdateSinglePost] = useState(props.singlePost);
  const [fetchedHorseProducts,setFetchedHorseProducts] = useState([]);
  const [fetchedRiderProducts,setFetchedRiderProducts] = useState([]);
  const [fetchedHorseSale,setFetchedHorseSale] = useState([]);
  const [updateProducts, setUpdateProducts] = useState(true);
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [singlePost,setSinglePost] = useState();
  const { post_id } = useParams();
  const {tag_value} = useParams();
  const [fetchTaggedPosts,setFetTaggedPosts] = useState();
  const [taggedPosts,setTaggedPosts] = useState([]);
  const [feedLoading, setFeedLoading] = useState(false);
  const [searchUsersLoading, setSearchUsersLoading] = useState(false);
  const [searchHorsesLoading, setSearchHorsesLoading] = useState(false);
  const [searchShopsLoading, setSearchShopsLoading] = useState(false);
  const [searchFarmsLoading, setSearchFarmsLoading] = useState(false);
  const [searchEventsLoading, setSearchEventsLoading] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [riderLoading, setRiderLoading] = useState(false);
  const [horseLoading, setHorseLoading] = useState(false);
  const [horseSaleLoading, setHorseSaleLoading] = useState(false);
  const [savedPostsLoading, setSavedPostsLoading] = useState(false);
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const [uploadProgress,setUploadProgress] = useState(0);
  const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? true : false;
  const [showBadge,setShowBadge] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching,setIsSearching] = useState(false)
  


  //*****************************************************************   general search   *******************************************************
  const fetchSearchResults = async (searchTerm) => {
    
    
    //search users:
    if(isSearching && props.searchType===0)
    {
      console.log(`searching for: ${searchTerm}`);
       setAllUsers([]);
       fetchWithSearchTerm();
       setSearchUsersLoading(true);
    
      
    }
    //search horses:
    else if(props.searchType===1)
    {
      console.log(`searching for: ${searchTerm}`);
       setAllHorses([]);
       fetchWithSearchTerm();
       setSearchHorsesLoading(true);
      
    }
    //search shops:
    else if(props.searchType===2)
    {
      console.log(`searching for: ${searchTerm}`);
       setAllShops([]);
       fetchWithSearchTerm();
       setSearchShopsLoading(true);
    }
    ///search farms:
    else if(props.searchType===3)
    {
      console.log(`searching for: ${searchTerm}`);
       setAllFarms([]);
       fetchWithSearchTerm();
       setSearchFarmsLoading(true);
    }
    //search events:
    else if(props.searchType===4)
    {
      console.log(`searching for: ${searchTerm}`);
       setAllEvents([]);
       fetchWithSearchTerm();
       setSearchEventsLoading(true);
       
    }
    //search rider products:
    else if(props.shopTab===0)
    {
      console.log(`searching for: ${searchTerm}`);
       setFetchedRiderProducts([]);
       fetchWithSearchTerm();
       setRiderLoading(true);
       
    }
    //search horse products:
    else if(props.shopTab===1)
    {
      console.log(`searching for: ${searchTerm}`);
       setFetchedHorseProducts([]);
       fetchWithSearchTerm();
       setHorseLoading(true);
       
    }
     //search horses for sale:
     else if(props.shopTab===2)
     {
       console.log(`searching for: ${searchTerm}`);
        setFetchedHorseSale([]);
        fetchWithSearchTerm();
        setHorseSaleLoading(true);
        
     }



  };

  useEffect(() => {
    // Create a debounced version of the API request
    const delayedApiRequest = setTimeout(() => {
      if (searchTerm) {
        fetchSearchResults(searchTerm);
      }
    }, 500);

    // Clear the timeout if the component unmounts or searchTerm changes
    return () => clearTimeout(delayedApiRequest);
  }, [searchTerm]);

  // Event handler for input change
  const handleInputChange = (event) => {
    const { value } = event.target;
    setIsSearching(true);
    setSearchTerm(value);
    if(value==="")
    {
      setIsSearching(false);
      if     (props.searchType===0)  setAllUsers([]);
      else if(props.searchType===1)  setAllHorses([]);
      else if(props.searchType===2)  setAllShops([]);
      else if(props.searchType===3)  setAllFarms([]);
      else if(props.searchType===4)  setAllEvents([]);
      else if(props.shopTab === 0)   {setFetchedRiderProducts([]); setUpdateProducts(true);}
      else if(props.shopTab === 1)   {setFetchedHorseProducts([]); setUpdateProducts(true);}
      else if(props.shopTab === 2)   {setFetchedHorseSale([]); setUpdateProducts(true);}
      
    }
  };
/*************************************************************************************************************************** */




  const fireworkslottieOptions = {
    loop: 3,
    autoplay: true,
    animationData: fireworks,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    isClickToPauseDisabled:true 
    
  };
  
  


  const randomise = (r) =>
  {
         //Fisher-yates shuffle algorithm:
         for(let i=r.length-1; i>0; i--)
         {
            const j = Math.floor(Math.random()*(i+1));
            [r[i],r[j]] = [r[j],r[i]];
         }

         return r;
  }


 
  

  const closeNewPost = (uploadFile,fileType,values,type) => {

    submitPost(uploadFile,fileType,values,type);
    setShowNewPostDialog(false);
    setShowNewProductDialog(false);
    setUpdatePosts(true);
    setUpdateSavedPosts(true);

  };
  
  const closeNewSinglePost = () => {
    setUpdateSinglePost(true);
    console.log("closeNewSinglePost");

  };
 
  const closeNewProduct = () => {
    setShowNewPostDialog(false);
    setUpdateSavedPosts(true);
    setUpdateProducts(true);
    
  };


  const refreshSavedPosts = () => {
    setUpdateSavedPosts(true);
    setUpdatePosts(true);
  };

  const submitPost = (uploadFile,fileType,values,type) =>
  {
    //uploading file
    let formData = new FormData();
    formData.append("file", uploadFile);

    const c = {
      headers: {
        "Contetnt-Type": "multipart/form-data",
      },
    };
    
    axios
      .post(process.env.REACT_APP_BACK+"/posts/uploadFile", formData, { c
      ,
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      },
      onDownloadProgress: (progressEvent) => {
    
      },
      })
      .then((res) => {
        //Now do what you want with the response;

        let fileName = res.data.fileName;


        //save tags:

var tagsString = values.tags ? String(values.tags) : "";
var t = tagsString.split(" ");

    //    var t = values.tags?.split(" ");
        for(const index in t)
        {
          //remove empty elements from array:
          if(t[index]==="")
            t.splice(index, 1);

          //remove # from each element:
          if(t[index]!=undefined)
          {
            t[index] = t[index].substring(1);
            t[index].trim();
          }
         

        }




        const data = JSON.stringify({
          
          description: values.description,
          category: values.category,
          isProduct:false,
          file:fileName,
          creator: user._id,
          creatorAvatar:user.avatarPic,
          creatorJob:user.job,
          creatorName:user.name,
          creatorUsername:user.username,
          accountType:user.accountType,
          type:type,
          fileType:fileType,
          tags:t
        });

        //creating post in database
       

        axios
          .post(process.env.REACT_APP_BACK+"/posts", data, config)
          .then((response) => {
            console.log("response",response);


            if(response.data.roset1)
                  setShowBadge(true);

            setUpdatePosts(true);
            setUpdateProducts(true);

            if(type==="product")
            {
              if(values.category==="Ù„ÙˆØ§Ø²Ù… Ø§Ø³Ø¨")
                navigate("/shop/horse");
              else if(values.category==="Ù„ÙˆØ§Ø²Ù… Ø³ÙˆØ§Ø±")
                navigate("/shop/rider");

            }

            
          })
          .catch((error) => {
            console.log("error",error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
      })
      .catch((error)=>
      {

console.log("errror",error);

        if(error.response?.data.statusCode===401)
              navigate("/login")
      });

  }


useEffect(() => {
  async function feedLoading() {

    setFeedLoading(true);
    setHorseLoading(true);
    setRiderLoading(true);
    
}
    
feedLoading(); 

},[]);

  //update feed for mobile:
useEffect(() => {
  async function mobileUpdate() {

    setUpdatePosts(true);
    
    
}
    
  mobileUpdate(); 

},[props.mobileUpdate]);


//fetch single post:
useEffect(() => {

  

  async function fetchSinglePosts() {



    axios.get(process.env.REACT_APP_BACK+"/posts/single/"+post_id, config)
    .then((result) =>{
      if (result.status == 200) {
        setSinglePost(result.data);
        console.log("single post",result.data);
        setFeedLoading(false);
        
        return result.data;
      }  
    })
    .catch((error) => {
      console.log("error",error);
      if(error.response.data.statusCode===401)
              navigate("/login")
    });
    


  }
    
    if(updateSinglePost === true)
    {

      
      fetchSinglePosts();
      setUpdateSinglePost(false);
   
    }
 
  

},[props,updateSinglePost]);




//fetch tagged posts:
useEffect(() => {
  async function fetchPostsOfTags() {

    let heightIndex = 1;
    let offset = 10;

    //fetch first group:
    await axios.get(process.env.REACT_APP_BACK+"/posts/contain-tag/"+tag_value+"/"+user._id+"/"+offset, config)
          .then((result) => {
            if (result.status == 200) {

              const { posts, totalCount } = result.data;
              setTaggedPosts(posts);
              console.log("tagged posts",posts);
    
              setFeedLoading(false);
              
              
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
    


  window.addEventListener('scroll', async function() {
  
  let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
  let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
  let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
  if (distanceScrolled >= (600*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
      
    
    heightIndex += 1;
      
    //fetch next:
    if(heightIndex === (offset))
    {
      offset += 10;
      await setLazyLoading(true);

      await axios.get(process.env.REACT_APP_BACK+"/posts/contain-tag/"+tag_value+"/"+user._id+"/"+offset, config)
      .then((result) => {
        if (result.status == 200) {
              
          const { posts, totalCount } = result.data;
          setTaggedPosts(prevArray => [...prevArray, ...posts]);
          console.log("tagged posts",posts);
          
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
        
    

      setLazyLoading(false);
      
      

    }

   
    }
  });


  }

    if(tag_value!=undefined)
      setFetTaggedPosts(true);

      
    
    
    if(props.singlePost===false && (  taggedPosts.length === 0)) 
    {
   
      fetchPostsOfTags();
    }
 
  

},[props]);



  /********************************************** search section ************************************************************/
   useEffect(() => {


    if( props.type == "search" && props.searchType == 0 && allUsers.length===0 && !isSearching)
    {
      
      setSearchUsersLoading(true);
      fetchPages("users");
    }
    else if(props.type == "search" && props.searchType == 1 &&  allHorses.length === 0 && !isSearching)
    {
      setSearchHorsesLoading(true);
      fetchPages("horses");
    }
   
    else if(props.type == "search" && props.searchType == 2 && allShops.length === 0 && !isSearching)
    {
 
      setSearchShopsLoading(true);
      fetchPages("shops");
      
    }
    else if(props.type == "search" && props.searchType == 3 && allFarms.length === 0 && !isSearching)
    {
      setSearchFarmsLoading(true);
      fetchPages("farms");
    }
    else if(props.type == "search" && props.searchType == 4 && allEvents.length === 0 && !isSearching)
    {
      setSearchEventsLoading(true);
      fetchPages("events");
    }
  
    
  }, [props.searchType,isSearching]);



  const fetchPages = async(type) =>
  {

    let heightIndex = 1;
    let offset = 10;

    let address="";

    if(type === "users")
      address = process.env.REACT_APP_BACK+"/users/search/";
    else if(type === "horses")
      address = process.env.REACT_APP_BACK+"/pages/search/horse/";
    else if(type === "shops")
      address = process.env.REACT_APP_BACK+"/pages/search/shop/";
    else if(type === "farms")
      address = process.env.REACT_APP_BACK+"/pages/search/farm/";
    else if(type === "events")
      address = process.env.REACT_APP_BACK+"/pages/search/event/";

    

    //fetch first group:
    await axios.get(address+offset, config).then((result) => {

            if (result.status == 200) {

              if(type === "users")
              {
                setAllUsers(randomise(result.data));
                setSearchUsersLoading(false);
                console.log("fetched searched users: ", result.data);
              }
              else if(type === "horses")
              {
                setAllHorses(randomise(result.data));
                setSearchHorsesLoading(false);
                console.log("fetched searched horses: ", result.data);
              }
              else if(type === "shops")
              {
                setAllShops(randomise(result.data));
                setSearchShopsLoading(false);
                console.log("fetched searched shops: ", result.data);
              }
              else if(type === "farms")
              {
                setAllFarms(randomise(result.data));
                setSearchFarmsLoading(false);
                console.log("fetched searched farms: ", result.data);
              }
              else if(type === "events")
              {
                setAllEvents(randomise(result.data));
                setSearchEventsLoading(false);
                console.log("fetched searched events: ", result.data)
              }
              
              
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
    


          window.addEventListener('scroll', async function() {
          
          let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
          let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
          let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
          if (distanceScrolled >= (86.6*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
      
    
    heightIndex += 1;
      
    //fetch next:
    if(heightIndex === (offset))
    {
      offset += 10;
      await setLazyLoading(true);

      await axios.get(address+offset, config)
      .then((result) => {
        if (result.status == 200) {


          if(type === "users")
              {
                setAllUsers(prevArray => [...prevArray, ...randomise(result.data)]);
                console.log("fetched searched users: ", result.data);
              }
              else if(type === "horses")
              {
                setAllHorses(prevArray => [...prevArray, ...randomise(result.data)]);
                console.log("fetched searched horses: ", result.data);
              }
              else if(type === "shops")
              {
                setAllShops(prevArray => [...prevArray, ...randomise(result.data)]);
                console.log("fetched searched shops: ", result.data);
              }
              else if(type === "farms")
              {
                setAllFarms(prevArray => [...prevArray, ...randomise(result.data)]);
                console.log("fetched searched farms: ", result.data);
              }
              else if(type === "events")
              {
                setAllEvents(prevArray => [...prevArray, ...randomise(result.data)]);
                console.log("fetched searched events: ", result.data);
              }

          
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
        
    

      setLazyLoading(false);
      
      

    }

   
    }
  });

  }

  const searchTabHandler = (e) => {
    if (e === 0) navigate("/search/users");
    if (e === 1) navigate("/search/horses");
    if (e === 2) navigate("/search/shops");
    if (e === 3) navigate("/search/farms");
    if (e === 4) navigate("/search/events");
    if (e === 5) navigate("/search/posts");
    
  };
  //***************************************************************************************************************************************







 /****************************************************    products     ************************************************************* */
 const shopTabHandler = (e) => {
  if (e === 0)
  {
    navigate("/shop/rider");
    if(fetchedRiderProducts.length==0)
      setUpdateProducts(true);
  }    
  if (e === 1) {
    navigate("/shop/horse");
    if(fetchedHorseProducts.length==0)
      setUpdateProducts(true);
  }
 

  if (e === 2)
  {
    navigate("/shop/horsesForSale");
    if(fetchedHorseSale.length==0)
      setUpdateProducts(true);
  }
};



 //fetch products:
useEffect(() => {



  //check if new product is added:
  const a = location.state != null ? location.state.updateProducts : false;
  
  if(props.type == "shop" && props.shopTab == 1 && (updateProducts||a))
  { 
    fetchProducts("horse");
  }
  else if(props.type == "shop" && props.shopTab == 0 && (updateProducts||a) )
  {
    fetchProducts("rider");
  }

  else if(props.type == "shop" && props.shopTab == 2 && updateProducts)
  {
    fetchProducts("horseForSale");
  }

  setUpdateProducts(false);

},[props,updateProducts,isSearching]);



const fetchProducts = async (type) =>
{
    let heightIndex = 1;
    let offset = 10;


    let address="";
    if(type==="horse")
      address = process.env.REACT_APP_BACK+"/posts/products/horse/";
    else if(type==="rider")
      address = process.env.REACT_APP_BACK+"/posts/products/rider/";
    else if(type==="horseForSale")
      address = process.env.REACT_APP_BACK+"/pages/shop/horses-for-sale/";

    //fetch first group:
    axios.get(address+offset, config).then((result) => {
            
        if (result.status == 200) {

              if(type==="horse")
              {
                setFetchedHorseProducts(randomise(result.data));
                console.log("horse products",result.data);
                setHorseLoading(false);
              }
              else if(type==="rider")
              {
                setFetchedRiderProducts(randomise(result.data));
                console.log("rider products",result.data);
                setRiderLoading(false);

              }
              else if(type==="horseForSale")
              {
                setFetchedHorseSale(randomise(result.data));
                setHorseSaleLoading(false);
                console.log("fetched horses for sale: ", result.data);
              }
              
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
    


        window.addEventListener('scroll', async function() {
        
        let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
        let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
        let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
        if (distanceScrolled >= (600*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
      
    
         heightIndex += 1;
      
          //fetch next:
          if(heightIndex === (offset))
          {
            offset += 10;
            await setLazyLoading(true);

            await axios.get(address+offset, config)
            .then((result) => {
              if (result.status == 200) {

                if(type==="horse")
                {
                  setFetchedHorseProducts(prevArray => [...prevArray, ...randomise(result.data)]);
                  console.log("horse products",result.data);
                  return result.data;
                  
                }
                else if(type==="rider")
                {
                  setFetchedRiderProducts(prevArray => [...prevArray, ...randomise(result.data)]);
                  console.log("rider products",result.data);
                  return result.data;
  
                }
                else if(type==="horseForSale")
                {
                  setFetchedHorseSale(prevArray => [...prevArray, ...randomise(result.data)]);
                  console.log("fetched horses for sale: ", result.data);
                  return result.data;
                }

                
              }
            })
            .catch((error) => {
              console.log(error);
              if(error.response.data.statusCode===401)
                    navigate("/login")
            });
      
      setLazyLoading(false);
      

    }

   
    }
  });
}
/********************************************************************************************************************************* */



  

/*******************************************************  fetch followings posts************************************************/
useEffect(() => {

  

  async function fetchPosts(_id) {

    
    let heightIndex = 1;
    let offset = 10;

    //fetch first group of posts:
    let result = await axios.get(process.env.REACT_APP_BACK+"/notifications/lazy-followings-posts/" + _id+"/"+offset, config);
    result = result.data;
    setFetchedPost(result);
    let showPublic=false;

    //if user follows no one, show public posts:
    if(result.length===0)
    {
      let publicResult = await (await axios.get(process.env.REACT_APP_BACK+"/posts/public/" + offset, config)).data;
      setFetchedPost(publicResult);
      showPublic=true;
      console.log("fetched public posts",publicResult);
    }
    else
    {
      console.log("fetched posts",result);
    }
    
    
    setFeedLoading(false);
    


    
  window.addEventListener('scroll', async function() {
  
  let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
  let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
  let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
  if (distanceScrolled >= (600*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
      
    
    heightIndex += 1;
      
    //fetch next:
    if(heightIndex === (offset))
    {
      offset += 10;
      await setLazyLoading(true);


      if(!showPublic) //user follows someone
      {
        let newResult = await axios.get(process.env.REACT_APP_BACK+"/notifications/lazy-followings-posts/" + _id+"/"+offset, config);
        setFetchedPost(prevArray => [...prevArray, ...newResult.data]);
        console.log("fetched posts",newResult.data);
      }
      else //if user follows no one, show public posts:
      {
        let newPublicResult = await (await axios.get(process.env.REACT_APP_BACK+"/posts/public/" + offset, config)).data;
        setFetchedPost(prevArray => [...prevArray, ...newPublicResult]);
        console.log("fetched public posts",newPublicResult);
    
      }

      setLazyLoading(false);
      
      

    }

   
    }
  });
    return result.data;
    

  }

  
 
  if(props.type == "feed" && updatePosts && tag_value==undefined && props.singlePost==undefined)
  {
    // setFeedLoading(true); 
    fetchPosts(user._id);
    setUpdatePosts(false);
  }

}, [props,updatePosts]);
/************************************************************************************************************************** */




/****************************************************** fetch saved posts******************************************************/
useEffect(() => {
  async function fetchSavedPosts(_id) {
    

    let heightIndex = 1;
    let offset = 10;

    //fetch first group:
    const result = 

    await axios.get(process.env.REACT_APP_BACK+"/notifications/saved-posts/" + _id+"/"+offset, config)
          .then((result) => {
            if (result.status == 200) {
              

              console.log("saved posts:",result.data);
    
              const a = result.data;
              a.map(a.pop,[...a]);
              setSavedPost(a);
              setSavedPostsLoading(false);

              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
    


  window.addEventListener('scroll', async function() {
  
  let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
  let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
  let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page
  if (distanceScrolled >= (600*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
      
    
    heightIndex += 1;
      
    //fetch next:
    if(heightIndex === (offset))
    {
      offset += 10;
      await setLazyLoading(true);

      await axios.get(process.env.REACT_APP_BACK+"/notifications/saved-posts/" + _id+"/"+offset, config)
      .then((result) => {
        if (result.status == 200) {
          console.log("saved posts:",result.data);
    
              const a = result.data;
              a.map(a.pop,[...a]);
              setSavedPost(prevArray => [...prevArray, ...a]);
              setSavedPostsLoading(false);
              

          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
        
    

      setLazyLoading(false);
      
      

    }

   
    }
  });

  }
  

    
    if(props.type === "notification" && updateSavedPosts)
    {
      setSavedPostsLoading(true);
      fetchSavedPosts(user._id);
      setUpdateSavedPosts(false);
   
    }
 
  

},[props,updateSavedPosts]);

// ***************************************************************************************************************************


const fetchWithSearchTerm = async () =>
{
    let heightIndex = 1;
    let offset = 10;

    let address;
    if(props.searchType===0)  address= "/users/search/";
    else if(props.searchType===1)  address= "/pages/search/horse/";
    else if(props.searchType===2)  address= "/pages/search/shop/";
    else if(props.searchType===3)  address= "/pages/search/farm/";
    else if(props.searchType===4)  address= "/pages/search/event/";
    else if(props.shopTab===0)     address= "/posts/search-rider-products/";
    else if(props.shopTab===1)     address= "/posts/search-horse-products/";
    else if(props.shopTab===2)     address= "/pages/search-horses-for-sale/";

  
    
    //fetch first group:
    await axios.get(process.env.REACT_APP_BACK+address+searchTerm+"/"+offset, config)
          .then((result) => {
            if (result.status == 200) {
              
              if(props.searchType===0)  {setAllUsers(randomise(result.data));setSearchUsersLoading(false); console.log("fetched searched users: ", result.data);}
              else if(props.searchType===1)  {setAllHorses(randomise(result.data));setSearchHorsesLoading(false); console.log("fetched searched horses: ", result.data);}
              else if(props.searchType===2)  {setAllShops(randomise(result.data));setSearchShopsLoading(false); console.log("fetched searched shops: ", result.data);}
              else if(props.searchType===3)  {setAllFarms(randomise(result.data));setSearchFarmsLoading(false); console.log("fetched searched farms: ", result.data);}
              else if(props.searchType===4)  {setAllEvents(randomise(result.data));setSearchEventsLoading(false); console.log("fetched searched events: ", result.data);}
              else if(props.shopTab===0)     {setFetchedRiderProducts(randomise(result.data));setRiderLoading(false); console.log("fetched searched rider products: ", result.data);}
              else if(props.shopTab===1)     {setFetchedHorseProducts(randomise(result.data));setHorseLoading(false); console.log("fetched searched horse products: ", result.data);}
              else if(props.shopTab===2)     {setFetchedHorseSale(randomise(result.data));setHorseSaleLoading(false); console.log("fetched searched horses for sale: ", result.data);}
              
              
              return result.data;
            }
          })
          .catch((error) => {
            console.log(error);
            if(error.response.data.statusCode===401)
              navigate("/login")
          });
    


      window.addEventListener('scroll', async function() {
      
      let scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;  
      let visibleHeight = document.documentElement.clientHeight;    // Get the visible height of the screen
      let distanceScrolled = scrollPosition + visibleHeight;        // Calculate the distance the user has scrolled from the top of the page

      let h;
      if(props.searchType)
        h=86.6;
      else if(props.shopTab)
        h=600;

      if (distanceScrolled >= (h*heightIndex) ) {                 // Check if the user has scrolled down a certain amount
          
        
        heightIndex += 1;
          
    //fetch next:
    if(heightIndex === (offset))
    {
      offset += 10;
      await setLazyLoading(true);

      await axios
      .get(process.env.REACT_APP_BACK+address+searchTerm+"/"+offset, config)
      .then((result) => {
        if (result.status == 200) {

          if(props.searchType===0)  {setAllUsers(prevArray => [...prevArray, ...randomise(result.data)]); console.log("fetched searched users: ", result.data);}
          else if(props.searchType===1)  {setAllHorses(prevArray => [...prevArray, ...randomise(result.data)]);console.log("fetched searched horses: ", result.data);}
          else if(props.searchType===2)  {setAllShops(prevArray => [...prevArray, ...randomise(result.data)]);console.log("fetched searched shops: ", result.data);}
          else if(props.searchType===3)  {setAllFarms(prevArray => [...prevArray, ...randomise(result.data)]);console.log("fetched searched farms: ", result.data);}
          else if(props.searchType===4)  {setAllEvents(prevArray => [...prevArray, ...randomise(result.data)]); console.log("fetched searched events: ", result.data);}
          else if(props.shopTab===0)     {setFetchedRiderProducts(prevArray => [...prevArray, ...randomise(result.data)]); console.log("fetched searched rider products: ", result.data);}
          else if(props.shopTab===1)     {setFetchedHorseProducts(prevArray => [...prevArray, ...randomise(result.data)]); console.log("fetched searched horse products: ", result.data);}
          else if(props.shopTab===2)     {setFetchedHorseSale(prevArray => [...prevArray, ...randomise(result.data)]); console.log("fetched searched horse for sale: ", result.data);}

          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
              navigate("/login")
      });
        
    

      setLazyLoading(false);
      
      

    }

   
    }
  });
}

 

  return (
    <div>
      



    {/* meta tags for tag page: */}
    {props.singlePost === false ? 
    
    <Helmet>
    
         :
        
       
                <title>{tag_value}</title>
                <meta name="description" content={"این صفحه شامل تمامی پست ها و محصولاتی است که برچسب"+" "+tag_value + " را دارند. برای مطالب بیشتر، فلیکا را دنبال کنید"}/>
                <meta name="robots" content="index, follow"/>
                <meta name="author" content={"admin"}/>
                <link rel="canonical" href={`${process.env.REACT_APP_SITE_URL}/tags/${tag_value}`}/>
                <meta property="og:title" content={tag_value}/>
                <meta property="og:description" content={"این صفحه شامل تمامی پست ها و محصولاتی است که برچسب"+" "+tag_value + " را دارند. برای مطالب بیشتر، فلیکا را دنبال کنید"}/>
                <meta property="og:image" content={`${process.env.REACT_APP_BACK}/avatars/ac043faf42692a0495f731250e24d5d3.png.png`}/>
                <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}/tags/${tag_value}`}/>
                <meta property="og:type" content="tag"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Tag",
                    "headline": tag_value,
                    "description": "این صفحه شامل تمامی پست ها و محصولاتی است که برچسب"+" "+tag_value + " را دارند. برای مطالب بیشتر، فلیکا را دنبال کنید",
                    "author": {
                      "@type": "Person",
                      "name": "admin"
                    },
                    "publisher": {
                      "@type": "Organization",
                      "name": "Example Publisher",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.example.com/logo.jpg"
                      }
                    },
                    "datePublished":"2024-07-28"
                  })}
                </script>
      
    </Helmet>
      : null}

      {/* meta tags for single post: */}
      {props.singlePost===true && singlePost ?
    
      <Helmet>
          <meta charSet="utf-8"/>
            <title>{singlePost._id}</title>
            <meta name="description" content={singlePost.description}/> : <meta name="description" content={singlePost.description}/>
            
      </Helmet>
      : null

    }


    {/* badge animation */}
    <div onClick={()=> setShowBadge(false)}>
    {showBadge && 
    
    <div style={{position:"absolute" , height:"100vh",zIndex:"1000"}} >
    <Lottie     options={fireworkslottieOptions}  />
    
      <div className="popup">
        <div className="popup-content">
          <img src={require("../images/rosette.png")} alt="Ø´Ø¨Ú©Ù‡ Ø§Ø¬ØªÙ…Ø§Ø¹ÛŒ Ø³ÙˆØ§Ø±Ú©Ø§Ø±ÛŒ " className="zoom-in" style={{width:"50%",height:"auto"}}/>
        </div>
      </div>
    </div>
    
    
    }
    </div>

      <Dialog
        
        className={props.theme+" new-post-dialog"}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        position="top"
        header="پست جدید"
        visible={showNewProductDialog}
        style={{ width: "40vw" }}
        onHide={() => setShowNewProductDialog(false)}
      >
        <NewPost progress={uploadProgress} type="product" user={user} close={closeNewPost}/>
      </Dialog>


{/* load animation: */}



      

{/*feed section*******************************************************************************************/}
      {props.type === "feed" ? (
        <div id="feed">
          
      {online ? 
          <a>
            <span id="create-post">
              <span id="feed-col1">
                <Button
                  onClick={() => setShowNewPostDialog(true)}
                  tooltip="پست جدید"
                  tooltipOptions={{
                    className: "create-post-tooltip",
                    position: "top",
                  }}
                >
                  <i className="pi pi-image"></i>
                </Button>
                <Button
                  onClick={() => setShowNewPostDialog(true)}
                  tooltip="پست جدید"
                  tooltipOptions={{
                    className: "create-post-tooltip",
                    position: "top",
                  }}
                >
                  <i className="pi pi-video"></i>
                </Button>
              </span>

              <span onClick={() => setShowNewPostDialog(true)} id="feed-col2">
                پست جدید ...
              </span>
              <span onClick={() => setShowNewPostDialog(true)} id="feed-col3">
              <img src={process.env.REACT_APP_BACK+`/avatars/${avatarPic}`}></img>
                
              </span>

              
            </span>

            <Dialog
              className={props.theme+" new-post-dialog"}
              breakpoints={{ "960px": "75vw", "640px": "100vw" }}
              position="top"
              header="پست جدید"
              visible={showNewPostDialog}
              style={{ width: "40vw" }}
              onHide={() => setShowNewPostDialog(false)}
            >
              <NewPost type="normal" user={user} close={closeNewPost}/>
            </Dialog>

           
          </a>
          :null }

          <div id="upload-progress" style={{display: (uploadProgress===100 || uploadProgress===0) ? "none" : "block"}}>
          
            <span>
          <div className="row1"><img src={process.env.REACT_APP_BACK+`/avatars/${avatarPic}`}></img></div>
          <div className="row2"><LinearProgress variant="determinate" value={uploadProgress} /></div>
          
          </span>
          </div>

          {/* load animation */}
          {feedLoading ? 
            <div className="spinner"></div>
        : null}



{/* if singlePost: */}

          {/* post of people */}
          {props.singlePost==true && singlePost && singlePost.creator!=null? 

          <div>
            
          <Post
            creatorUsername={singlePost.creator.username}
            creator_id={singlePost.creator._id}
            creatorName={singlePost.creator.name}
            creatorAvatar={singlePost.creator.avatarPic}
            creatorJob={singlePost.creator.job}
            file={singlePost.file}
            fileType={singlePost.fileType}
            category={singlePost.category}
            tags={singlePost.tags}
            taggedUsers={singlePost.taggedUsers}
            date={singlePost.date}
            description={singlePost.description}
            likesCount={singlePost.likesCount}
            commentsCount={singlePost.commentsCount}
            postOfOther={singlePost.creator._id != user._id}
            _id={singlePost._id}
            updatePost={closeNewSinglePost}
            //online user data used for like & save & comment:
            userAvatar={user.avatarPic}
            user_id={user._id}
            user_name={user.name}
            user_username={user.username}
            theme={props.theme}
            blueTick={singlePost.creator.blueTick}
          />

          </div>
          : null }

          {/* post of a page */}
          {props.singlePost==true && singlePost
           && singlePost.creatorPage!=null? 

           
          <div>
          <Post
            creatorPage = {singlePost.creatorPage}
            creatorUsername={singlePost.creatorPage.username}
            creator_id={singlePost.creatorPage._id}
            creatorName={singlePost.creatorPage.name}
            creatorAvatar={singlePost.creatorPage.avatarPic}
            file={singlePost.file}
            fileType={singlePost.fileType}
            category={singlePost.category}
            tags={singlePost.tags}
            taggedUsers={singlePost.taggedUsers}
            date={singlePost.date}
            description={singlePost.description}
            likesCount={singlePost.likesCount}
            commentsCount={singlePost.commentsCount}
            postOfOther={singlePost.creatorPage.creator_id != user._id && singlePost.groupUser!==user._id}
            _id={singlePost._id}
            updatePost={closeNewPost}
            //online user data used for like & save & comment:
            userAvatar={user.avatarPic}
            user_id={user._id}
            user_name={user.name}
            user_username={user.username}
            theme={props.theme}
            blueTick={singlePost.creatorPage.blueTick}
          />

          </div>
          : null }


{/* if tagged posts: */}

          {props.singlePost === false ?
          taggedPosts.map((post, index) => (
            
            <div key={index}>
      
            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined ? post.creator.job : post.creatorPage.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            taggedUsers={post.taggedUsers}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={post.creator===undefined ? (post.creatorPage.creator_id !== user._id && post.groupUser!==user._id) : post.creator._id !== user._id}
            _id={post._id}
            updatePost={closeNewPost}
            //online user data used for like & save & comment:
            userAvatar={user.avatarPic}
            user_id={user._id}
            user_name={user.name}
            user_username={user.username}
            theme={props.theme}
            blueTick={post.creator!==undefined ? post.creator.blueTick : post.creatorPage.blueTick}
          />
          <br />
          </div> ))
            : null}

      

            { props.singlePost == undefined ?
            // if fetched posts:
          fetchedPosts.map((post, index) => (
            
            <div key={index}>
      
            {/* if we don't have creator or creatorPage: */}

            
            {  (post.creator!==null && post.creator!==undefined) || (post.creatorPage!==null && post.creatorPage!==undefined) ?    
            
            <Post
            creatorPage={post.creatorPage}
            creatorUsername={post.creator!==undefined && post.creator!==null ? post.creator.username : post.creatorPage.username}
            creator_id={post.creator!==undefined && post.creator!==null ? post.creator._id : post.creatorPage._id}
            creatorName={post.creator!==undefined && post.creator!==null ? post.creator.name : post.creatorPage.name}
            creatorAvatar={post.creator!==undefined && post.creator!==null ? post.creator.avatarPic : post.creatorPage.avatarPic}
            creatorJob={post.creator!==undefined && post.creator!==null ? post.creator.job : post.creatorPage.job}
            reposters_ids={post.reposters_ids}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            taggedUsers={post.taggedUsers}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={post.creator!==undefined ? post.creator._id != user._id : (post.creatorPage.creator_id!==user._id && post.groupUser!==user._id)}
            _id={post._id}
            updatePost={closeNewPost}
            //online user data used for like & save & comment:
            userAvatar={user.avatarPic}
            user_id={user._id}
            user_name={user.name}
            user_username={user.username}
            theme={props.theme}
            blueTick={post.creator!==undefined && post.creator!==null ? post.creator.blueTick : post.creatorPage.blueTick}
          />

          : null}


          <br />
          </div>)) :null}

          
          {/* load animation */}
          {lazyLoading ? 
            <div className="spinner"></div>
        : null}

          
        </div>
      ) : null}


{/*search section*******************************************************************************************/}

      {props.type === "search" ? (
        <div id="search-section">



          <span className="p-input-icon-right search-box-wraper">
            <i className="pi pi-search" />
            <InputText
              className="search-box"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="اینجا جستجو کنید ..."
              
            />
          </span>

          <TabView
            id="search-tab"
            style={{ direction: "rtl" }}
            activeIndex={props.searchType}
            onTabChange={(e) => searchTabHandler(e.index)}
          >
            <TabPanel header="کاربر">

            {/* loading animation */}
            {searchUsersLoading ? 
            <div className="spinner"></div>
        : null}

              <h5>همه کاربران</h5>
              {allUsers.map((post, index) => {
                  return (
                    <div className={"user" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        <SuggestionRow
                          key={index}
                          username={post.username}
                          name={post.name}
                          job={post.job}
                          image={post.avatarPic}
                          _id={post._id}
                          isPage={false}
                          isPost = {false}
                          group_id = {undefined}
                          isUserPage= {false}
                          online = {true}
                          forSearch={true}
                          blueTick={post.blueTick}
                          
                        ></SuggestionRow>
                      </div>
                    </div>
                  );
                })}


              {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}


            </TabPanel>
            <TabPanel header="اسب">

              {/* loading animation */}
            {searchHorsesLoading ? 
            <div className="spinner"></div>
        : null}

              <h5>همه اسب ها</h5>
              
              {allHorses.map((post, index) => {
                  
                  return (
                    
                    <div className={"page" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        
                        <SuggestionRow
                          key={index}
                          _id={post._id}
                          username={post.username}
                          name={post.name}
                          image={post.avatarPic}
                          isPage={true}
                          pageCreator_id={post.creator_id}
                          isPost = {false}
                          group_id = {undefined}
                          forSearch={true}
                          blueTick={post.blueTick}
                          
                        ></SuggestionRow>
                      </div>
                    </div>
                  );
                })}

                {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}
            </TabPanel>
            <TabPanel header="فروشگاه">

              {/* loading animation */}
            {searchShopsLoading ? 
            <div className="spinner"></div>
        : null}

              <h5>همه فروشگاه ها</h5>
              
              {allShops.map((post, index) => {
                  
                  return (
                    
                    <div className={"page" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        
                        <SuggestionRow
                          key={index}
                          _id={post._id}
                          username={post.username}
                          name={post.name}
                          description={post.description}
                          image={post.avatarPic}
                          isPage={true}
                          pageCreator_id={post.creator_id}
                          isPost = {false}
                          group_id = {undefined}
                          online = {true}
                          forSearch={true}
                          blueTick={post.blueTick}
                        ></SuggestionRow>
                      </div>
                    </div>
                  );
                })}

                {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}


            </TabPanel>
            <TabPanel header="باشگاه">

              {/* loading animation */}
            {searchFarmsLoading ? 
            <div className="spinner"></div>
        : null}

              <h5>همه باشگاه ها</h5>
              
              {allFarms.map((post, index) => {
                  
                  return (
                    
                    <div className={"page" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        
                        <SuggestionRow
                          key={index}
                          _id={post._id}
                          username={post.username}
                          name={post.name}
                          image={post.avatarPic}
                          isPage={true}
                          pageCreator_id={post.creator_id}
                          isPost = {false}
                          group_id = {undefined}
                          online = {true}
                          forSearch={true}
                          blueTick={post.blueTick}
                        ></SuggestionRow>
                      </div>
                    </div>
                  );
                })}

                {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}

            </TabPanel>
            <TabPanel header="مسابقه">

              {/* loading animation */}
            {searchEventsLoading ? 
            <div className="spinner"></div>
        : null}

              <h5>همه مسابقه ها</h5>
              
              {allEvents.map((post, index) => {
                  
                  return (
                    
                    <div className={"page" + index} key={index}>
                      <div id="suggestion-box" className="search-row">
                        
                        <SuggestionRow
                          key={index}
                          _id={post._id}
                          username={post.username}
                          name={post.name}
                          image={post.avatarPic}
                          isPage={true}
                          pageCreator_id={post.creator_id}
                          isPost = {false}
                          group_id = {undefined}
                          online = {true}
                          forSearch={true}
                          blueTick={post.blueTick}
                        ></SuggestionRow>
                      </div>
                    </div>
                  );
                })}
                {/* load animation */}
          {lazyLoading ? 
            <div className="spinner" style={{marginBottom:"50px"}}></div>
        : null}


            </TabPanel>

        
            
            
         


          


          </TabView>
        </div>
      ) : null}

{/*notification section*******************************************************************************************/}

      {props.type === "notification" ? (
        <div id="notification-section">


          
          <h5 style={{ textAlign: "right" }}>پست های ذخیره شده</h5>
          
          {/* loading animation */}
          {savedPostsLoading ? 
            <div className="spinner"></div>
        : null}

          {savedPosts.map((post, index) => (
            
            <div key={index}>
      
            {post!==null && <Post
            creatorPage={post.creatorPage}
            creatorUsername={ post.creator===undefined ? post.creatorPage.username : post.creator.username}
            creator_id={post.creator===undefined ? post.creatorPage._id : post.creator._id}
            creatorName={post.creator===undefined ? post.creatorPage.name : post.creator.name}
            creatorAvatar={post.creator===undefined ? post.creatorPage.avatarPic : post.creator.avatarPic}
            creatorJob={post.creator===undefined ? "" :post.creator.job}
            file={post.file}
            fileType={post.fileType}
            category={post.category}
            tags={post.tags}
            taggedUsers={post.taggedUsers}
            date={post.date}
            description={post.description}
            likesCount={post.likesCount}
            commentsCount={post.commentsCount}
            postOfOther={post.creator===undefined ? post.creatorPage.creator_id !== user._id : post.creator._id !== user._id}
            _id={post._id}
            updatePost={refreshSavedPosts}
            //online user data used for like & save & comment:
            userAvatar={user.avatarPic}
            user_id={user._id}
            user_name={user.name}
            user_username={user.username}
            theme={props.theme}
            blueTick={post.creator===undefined ? post.creatorPage.blueTick : post.creator.blueTick}
          /> }
          <br />
          </div>

          ))}


        </div>
      ) : null}

{/*shop section*******************************************************************************************/}
      {props.type === "shop" ? (
        <div id="shop-section">
          <div id="shop-search-box">
            <div id="shop-search-box-header"
              style={{
               background: "#ffffff",
                margin: desktop ? "0px 0px 0px 0px" : "0px 0px -4px 0px",
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.15)"
              }}
            >
              <div className="p-input-icon-right search-box-wraper">
                <i className="pi pi-search" style={{right:"10px"}}/>
                <InputText
                  className="search-box"
                  value={searchTerm}
                  onChange={handleInputChange}
                  placeholder="اینجا جستجو کنید ..."
                />
                <i id="plus-icon"
                style={{ left:"10px",textAlign:"left"}} className="pi pi-plus" 
                onClick={()=>
                  {
                    if(desktop)
                    setShowNewProductDialog(true)
                  else
                    navigate("/new-product");

                  }
                
                
                }
                ></i>
              </div>
            </div>

            <TabView
              id="search-tab"
              style={{ direction: "rtl" }}
              activeIndex={props.shopTab}
              onTabChange={(e) => shopTabHandler(e.index)}
            >

          <TabPanel header="لوازم سوار">
              <div id="horse-products">

                {/* loading animation */}
            {riderLoading ? 
            <div className="spinner"></div>
        : null}

                  {fetchedRiderProducts.map((post, index) => {

                      return (

                        

                        <div className={"product" + index} key={index}>


                          <div>
                          <Post
                              creatorPage={post.creatorPage}
                              creatorUsername={ post.creator===undefined ? post.creatorPage.username : post.creator.username}
                              creator_id={post.creator===undefined ? post.creatorPage._id : post.creator._id}
                              creatorName={post.creator===undefined ? post.creatorPage.name : post.creator.name}
                              creatorAvatar={post.creator===undefined ? post.creatorPage.avatarPic : post.creator.avatarPic}
                              creatorJob={post.creator===undefined ? "" :post.creator.job}
                              file={post.file}
                              fileType={post.fileType}
                              category={post.category}
                              tags={post.tags}
                              taggedUsers={post.taggedUsers}
                              date={post.date}
                              description={post.description}
                              likesCount={post.likesCount}
                              commentsCount={post.commentsCount}
                              postOfOther={post.creator===undefined ? post.creatorPage.creator_id !== user._id : post.creator._id !== user._id}
                              _id={post._id}
                              updatePost={closeNewProduct}
                              //online user data used for like & save & comment:
                              userAvatar={user.avatarPic}
                              user_id={user._id}
                              user_name={user.name}
                              user_username={user.username}
                              theme={props.theme}
                              blueTick={post.creator===undefined ? post.creatorPage.blueTick : post.creator.blueTick}
                          /> 
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* load animation */}
      {lazyLoading ? 
            <div className="spinner"></div>
        : null}


              </TabPanel>

              <TabPanel header="لوازم اسب">
                <div id="horse-products">

                  {/* loading animation */}
            {horseLoading ? 
            <div className="spinner"></div>
        : null}
                  {fetchedHorseProducts.map((post, index) => {
                      return (
                        <div className={"product" + index} key={index}>
                          <div>
                            
                            <Post
                              creatorPage={post.creatorPage}
                              creatorUsername={ post.creator===undefined ? post.creatorPage.username : post.creator.username}
                              creator_id={post.creator===undefined ? post.creatorPage._id : post.creator._id}
                              creatorName={post.creator===undefined ? post.creatorPage.name : post.creator.name}
                              creatorAvatar={post.creator===undefined ? post.creatorPage.avatarPic : post.creator.avatarPic}
                              creatorJob={post.creator===undefined ? "" :post.creator.job}
                              file={post.file}
                              fileType={post.fileType}
                              category={post.category}
                              tags={post.tags}
                              taggedUsers={post.taggedUsers}
                              date={post.date}
                              description={post.description}
                              likesCount={post.likesCount}
                              commentsCount={post.commentsCount}
                              postOfOther={post.creator===undefined ? post.creatorPage.creator_id !== user._id : post.creator._id !== user._id}
                              _id={post._id}
                              updatePost={closeNewProduct}
                              //online user data used for like & save & comment:
                              userAvatar={user.avatarPic}
                              user_id={user._id}
                              user_name={user.name}
                              user_username={user.username}
                              theme={props.theme}
                              blueTick={post.creator===undefined ? post.creatorPage.blueTick : post.creator.blueTick}
                          />    




                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* load animation */}
      {lazyLoading ? 
            <div className="spinner"></div>
        : null}

     
              </TabPanel>

              

              <TabPanel header="اسبِ فروشی">
                <div id="horseForSale"></div>

                {horseSaleLoading ? 
            <div className="spinner"></div>
                : null}

          {fetchedHorseSale.map((horse, index) => {
                      return (
                    
                        <div className={"page" + index} key={index}>
                          <div id="suggestion-box" className="search-row">
                            
                            <SuggestionRow
                              key={index}
                              _id={horse._id}
                              username={horse.username}
                              name={horse.name}
                              image={horse.avatarPic}
                              isPage={true}
                              pageCreator_id={horse.creator_id}
                              isPost = {false}
                              group_id = {undefined}
                              forSearch={true}
                            ></SuggestionRow>
                          </div>
                        </div>
                      );
                    })}

                    {/* load animation */}
      {lazyLoading ? 
            <div className="spinner"></div>
        : null}

                 
              </TabPanel>

              
             
            </TabView>

            

            
          </div>
        </div>
      ) : null}

      {/* load animation
      {lazyLoading ? 
            <div className="spinner"></div>
        : null} */}
    </div>
  );
}

export default Feed;
