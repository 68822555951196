import "../Css/Post.css";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import React, { createRef, useEffect, useRef, useState } from "react";
import UseAnimations from 'react-useanimations';
import heart from 'react-useanimations/lib/heart';
import bookmark from 'react-useanimations/lib/bookmark';
import axios from "axios";
import { Dialog } from "primereact/dialog";
import EditPost from "./EditPost";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { LinkedinShareButton,LinkedinIcon,EmailIcon,EmailShareButton,WhatsappShareButton,WhatsappIcon,FacebookShareButton,FacebookIcon } from 'react-share';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Fieldset } from 'primereact/fieldset';
import { Divider } from "primereact/divider";
import SuggestionRow from "./SuggestionRow";
import Comment from "./Comment";
import "video-react/dist/video-react.css"; 
import { Player } from "video-react";




 

 

function Post(props) {

  const [isLiked, setIsLiked] = useState(false);
  const [isSaved,setIsSaved] = useState(false);
  const [showUpdateDialog,setShowUpdateDialog] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [replies,setReplies] = useState([]);
  const [commentsCount,setCommentsCount] = useState(props.commentsCount);
  const toast = createRef();
  const token = JSON.parse(localStorage.getItem('userToken'));
  const config = {  headers: {"Content-Type": "application/json",Authorization: `Bearer ${token}`} };
  const [showConfirmDelete,setShowConfirmDelete] = useState(false);
  const [showConfirmRepostDelete,setShowConfirmRepostDelete] = useState(false);
  const navigate = useNavigate();
  const op = useRef();
  const online = JSON.parse(localStorage.getItem('flickerNetwork')) != null ? true : false;
  const onlineUser = JSON.parse(localStorage.getItem('flickerNetwork') != null) ? JSON.parse(localStorage.getItem('flickerNetwork')) : {username:"" , name:"", avatarPic:"profile-index.png"};
  const [likesCount,setLikeCount] = useState(props.likesCount);
  const [showViewMore, setShowViewMore] = useState(true);
  const [descriptionLinesCount,setDescriptionLinesCount] = useState(0);
  const [showWhoLiked,setShowWhoLiked] = useState(false);
  const [whoLikedList,setWhoLikedList] = useState([]);
  const descRef = useRef();
  const [desktop, setDesktop] = useState(window.matchMedia("(min-width: 768px)").matches);
  const [reply,setReply] = useState(false);
  const [commentPlaceholder,setCommentPlaceHolder] = useState("نظر خود را بنویسید ...");
  const [replyToData,setReplyToData] = useState();
  const [showRepost,setShowRepost] = useState(false);
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const [isPlaying,setIsPlaying] = useState(true);
  const [theme,setTheme] = useState(props.theme);
  const [taggedUsersData,setTaggedUsersData] = useState([]);
  const [taggedUsersAnimation,settaggedUsersAnimation] = useState(true);
  const postRef = useRef(null);
  const [showTaggedUsers,setShowTaggedUsers] = useState(false);



  //fetch tagged users:
  useEffect( () =>
  {

    async function fetchtaggedUsersData() 
    {
      await axios.get(process.env.REACT_APP_BACK+"/users/taggedUsers/"+props.taggedUsers, config)
      .then(async(response) => {

        if(response.status == 200)
        {
          setTaggedUsersData(response.data);
          console.log("taggedUsers",response.data);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
            navigate("/login")
        });   

    }
  
    if(showUpdateDialog || showTaggedUsers)
      fetchtaggedUsersData();

  }
  ,[showUpdateDialog,showTaggedUsers]);

  // Trigger the tagged Users animation:
  const isPostVisible = () => 
  {
    
    if (!postRef.current) return false;
  
    const { top, bottom } = postRef.current.getBoundingClientRect();
  
    return (top < window.innerHeight && bottom >= 0);
    

  }


  

  
  const splitTextByLines = (text) => {
    const lines = text.split('\n');
  
    const rtlCharsRegex = /[\u0600-\u06FF\uFB8A\u067E\u0686\u06AF\u200C\u200F\u0080-\uFFFF]+/; // Farsi (Persian), Arabic, and extended characters
    const isRtl = (str) => rtlCharsRegex.test(str);
  
    return lines.map((line, index) => {
      if (line.match(/\bhttps?:\/\/[^\s]+/)) {
        return <a key={index} href={line} target="_blank" rel="noopener noreferrer">{line}</a>;
      } else if (isRtl(line)) {
        return <p key={index} className="rtl-line">{line}</p>;
      } else {
        return <p key={index} className="ltr-line">{line}</p>;
      }
    });
  };


  const navigateToShopCat = () =>
  {
    if(props.category==="لوازم اسب")
      navigate("/shop/horse");
    else if(props.category==="لوازم سوار")
    navigate("/shop/rider");

  }

  const processComments = (list) =>
  {
      let c = [];
      let r = {};

      list.forEach((comment, index) => {
        
        //if comment:
        if (comment.reply_to_comment===undefined) {
          c.push(comment);
        }
        //if reply:
        else
        {
          
          const key = comment.reply_to_comment;
          const value = comment;

          let keyNotExists = !(key in r);
          if(keyNotExists)
            r[key] = [];
    
            
          
          r[key].push(value);
    
          
          
        }

      });
      
      setComments(c);
      setReplies(r);



  }

  const replyClicked = (replyToData) =>
  {
    if(!reply)
    {
        setReply(true);
        setReplyToData(replyToData)
        setCommentPlaceHolder("@ "+replyToData.name + ": ");
        
    }
    else
    {
      setReply(false);
      setCommentPlaceHolder("نظر خود را بنویسید ...")

    }
  }


    //who liked this post:
    useEffect(() => {
    async function whoLiked() 
    {
      await axios.get(process.env.REACT_APP_BACK+"/notifications/whoLiked/"+props._id, config)
      .then(async(response) => {

        if(response.status == 200)
        {
          setWhoLikedList(response.data);
          console.log("whoLikedList",response.data);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
            navigate("/login")
        });      

    }
        
    if(showWhoLiked)
      whoLiked(); 

    },[showWhoLiked]);


  

  const  like =  async () => {
    
    //like:
    if(!isLiked)
    {
      const data = JSON.stringify({sender_id:props.user_id,sender_username:props.user_username ,sender_name:props.user_name,sender_avatar:props.userAvatar,reciever_id:props.creator_id,type:"like",post_id:props._id});
      await axios.post(process.env.REACT_APP_BACK+"/notifications/like", data, config).then(async(response) => {

        if(response.status == 201)
        {
          await setIsLiked(!isLiked);
          console.log(response);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
            navigate("/login")
        });

        await setLikeCount(likesCount+1);

    }
    //unlike:
    else
    {
      await axios
        .delete(process.env.REACT_APP_BACK+"/notifications/like/"+props.user_id+"/"+props._id, config)
        .then(async (response) => {

        if(response.status == 200)
        {
          await setIsLiked(!isLiked);
          console.log("unlike",response);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
            navigate("/login")
        });

        await setLikeCount(likesCount-1);

    }

    
    
  };

  const save =  () => {

    //save:
    if(!isSaved)
    {
      const data = JSON.stringify({sender_id:props.user_id,sender_avatar:props.userAvatar,reciever_id:props.creator_id,type:"save",post_id:props._id});
      axios
        .post(process.env.REACT_APP_BACK+"/notifications/save", data, config)
        .then((response) => {

        if(response.status == 201)
        {
          setIsSaved(!isSaved);
          console.log(response);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
            navigate("/login")
        });

    }
    else
    {
      //unsave:
      axios
        .delete(process.env.REACT_APP_BACK+"/notifications/save/"+props.user_id+"/"+props._id, config)
        .then((response) => {

        if(response.status == 200)
        {
          setIsSaved(!isSaved);
          console.log("unsave",response);
        }
        })
        .catch((error) => {
          console.log("error",error);
          if(error.response.data.statusCode===401)
            navigate("/login")
        });

    }

    
    props.updatePost();
    
  };





  //is post liked?:
  useEffect(() => {
    async function isLiked() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/like/"+props.user_id+"/"+props._id, config)
      setIsLiked(result.data);
      return result.data;

    }
    isLiked();
  },);

  //is post saved?:
  useEffect(() => {
    async function isSaved() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/save/"+props.user_id+"/"+props._id, config)
      setIsSaved(result.data);
      return result.data;

    }
    isSaved();
  },);

  //fetch comments:
  useEffect(() => {
    async function fetchComments() {

      const result = await axios.get(process.env.REACT_APP_BACK+"/notifications/comment/"+props._id, config)
      .then((result) => {
        if (result.status == 200) {
          processComments(result.data);
          console.log("fetched comments: ", result.data);
          return result.data;
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.response.data.statusCode===401)
          navigate("/login")
      });
      
      
      return result.data;

    }
    
    if(showComments)
      fetchComments();

  },[showComments,commentsCount]);

 


 

  const closeEditPost = () => {
    setShowUpdateDialog(false);
    props.updatePost();
  };

  //commment form:
  const formik = useFormik({
    initialValues: {
      content:""
    },
    onSubmit: (values) => {
      
          const temp = {
            content: values.content,
            sender_id: props.user_id,
            reciever_id: props.creator_id,
            type:"comment",
            post_id: props._id
          };

          if(reply)
          {
            temp["reply_to_comment"]= replyToData.comment_id;
            temp["reply_to_user"] = replyToData.user_id;
            setReply(false);
          }


          const data = JSON.stringify(temp);

          

          axios
            .post(process.env.REACT_APP_BACK+"/notifications/comment/", data, config)
            .then((response) => {
              console.log(response);
              setShowComments(true);
              setCommentsCount(commentsCount+1);
            })
            .catch((error) => {
              console.log("error",error);
              if(error.response.data.statusCode===401)
                navigate("/login")
            });

            formik.resetForm();
            setReply(false);
            setCommentPlaceHolder("نظر خود را بنویسید ...");
            setReplyToData(undefined);

        }
        ,validate: (values) => {
          let errors = {};
    
          if (!values.content) {
            errors.name = "نظر خود را بنویسید";
          }
    
          return errors;
        }

  });


  const deletePost = async (_id) => 
    {
      await axios.delete(process.env.REACT_APP_BACK+"/posts/" + _id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);
            props.updatePost();
            toast.current.show({
              severity: "error",
              summary: "حذف",
              detail: "پست حذف شد",
            });
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
          navigate("/login")
      });
    }

    const repost = () =>
    {
      const data = JSON.stringify({reposter_id:onlineUser._id});
    
              
             
              axios
                .patch(process.env.REACT_APP_BACK+"/posts/repost/"+props._id, data, config)
                .then((response) => {

                if(response.status == 200)
                {
                  console.log(response);
                  props.updatePost();
                }
                })
                .catch((error) => {
                  console.log("error",error);
                  if(error.response.data.statusCode===401)
                    navigate("/login")
                });

                setShowRepost(false);

    }
   
    const deleteRepost = async () =>
    {
      await axios.delete(process.env.REACT_APP_BACK+"/posts/repost/" + props._id + "/" + props.reposters_ids[0]._id, config)
      .then(response => 
        {
          if (response.status == 200)
          {
            console.log(response);
            props.updatePost();
            toast.current.show({
              severity: "error",
              summary: "حذف",
              detail: "بازنشر حذف شد",
            });
            
          }

      })
      .catch(error => {
         console.log(error);
         if(error.response.data.statusCode===401)
          navigate("/login")
      });

    }




  const editItems = [
    {
      label: "ویرایش",
      icon: "pi pi-pencil",
      command: (e) => {
     
        setShowUpdateDialog(true);

      },
    },
    {
      label: "حذف",
      icon: "pi pi-times",
      command: (e) => {

        setShowConfirmDelete(true);
       

        
      },
    },
  ];


  //calculate description lines:
  useEffect(() => {
    // Calculate lines based on height
    if (descRef.current) {
      const height = descRef.current.offsetHeight;
      setDescriptionLinesCount(Math.floor(height / 30)); // Assuming 30px per line
    }
  }, [props.description]);


      //video section:

// Function to check if the player is currently visible on the screen
const isPlayerVisible = () => {
  if (!playerRef.current) return false;
  const { top, bottom } = playerRef.current.getBoundingClientRect();
  return top < window.innerHeight && bottom >= 0;
};

// Event handler for the scroll event
const handleScroll = () => {

  if (isPlayerVisible()) {
    if (!isPlaying) {
      setIsPlaying(true);
    }
  } else {
    if (isPlaying) {
      setIsPlaying(false);

      if(videoRef.current)
        videoRef.current.pause();
    }
  }



    settaggedUsersAnimation(isPostVisible());
  
  
};

useEffect(() => {
  // Add scroll event listener
  window.addEventListener('scroll', handleScroll);

  // Cleanup
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

const getRandomPosition = (max) => Math.floor(Math.random() * max);


  return (
    <div id="post" >



      {/* who liked dialog: */}
      <Dialog
      id="suggestion-box"
      className={theme+" likesDialog"}
      visible={showWhoLiked}
      header={"لایک ها"}
      style={{ width: !desktop ? "75%" : "30%"}}
      onHide={() => setShowWhoLiked(false)} 
      dismissableMask={true}     
      >
        <div><Divider style={{ marginTop: "0px" }} /></div>

            {whoLikedList.map((follower, index) => (
            
            follower!==null && <SuggestionRow
              key={index}
              name={ follower.sender_id!==null && follower.sender_id!==undefined ? follower.sender_id.name : "کاربر حذف شده"}
              job={ follower.sender_id!==null && follower.sender_id!==undefined ? follower.sender_id.job : ""}
              image={follower.sender_id!==null && follower.sender_id!==undefined ?  follower.sender_id.avatarPic : "deletedAcount-index.png"}
              username={follower.sender_id!==null && follower.sender_id!==undefined? follower.sender_id.username : "undefined"}
              _id={follower.sender_id!==null && follower.sender_id!==undefined?  follower.sender_id._id : "undefined"}
              isPage={false}
              forSearch={true}
              isPost = {false}
              group_id = {undefined}
              isUserPage= {false}
              online = {true}
            ></SuggestionRow>

          ))}
      </Dialog>

      <Dialog
      className={theme+" new-post-dialog"}
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      position="top"
      header="ویرایش پست"
      style={{ width: "40vw" }}
      onHide={() => setShowUpdateDialog(false)}
      visible={showUpdateDialog}
      >
        <EditPost 
        post={{_id:props._id,file:props.file,fileType:props.fileType,category:props.category,tags:props.tags,description:props.description,taggedUsers:taggedUsersData}} 
        user={{name:props.creatorName,avatarPic:props.creatorAvatar}} 
        close={closeEditPost}>
        </EditPost>

      </Dialog>

      <ConfirmDialog 
            className={theme}
            visible={showRepost} 
            onHide={() => setShowRepost(false)} 
            message="میخواهید این پست را بازنشر کنید؟"
            header="تایید بازنشر"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => repost(props._id)} 
            reject={() => setShowRepost(false)} />


       

      <Card className="post">

      {props.reposters_ids ? 
      

      //repost section***************************************************************************************************************
      <div id="repost-header" style={{borderBottom:"1px solid rgb(37 37 37 / 18%)"}}>

        <span id="post-col1">

        {/* edit options: */}
        {props.reposters_ids[0]._id === onlineUser._id ? 
        <i className="pi pi-trash" 
        style={{color:"rgb(37 37 37 / 50%)" , fontSize:"13px"}}
        onClick={() => setShowConfirmRepostDelete(true)}
        ></i>

        :null
        }

        <ConfirmDialog 
        className={theme}
        visible={showConfirmRepostDelete} 
        onHide={() => setShowConfirmRepostDelete(false)} 
        message="آیا از حذف این بازنشر مطمئنید؟"
        header="تایید حذف"
        icon="pi pi-info-circle"
        acceptLabel="بله"
        rejectLabel="نه"
        acceptClassName="accept"
        rejectClassName="reject"
        accept={() => deleteRepost(props._id)} 
        reject={() => setShowConfirmRepostDelete(false)} />

        <Toast ref={toast} position="top-center" />
        </span>
          
        <span id="post-col2">
        <div>
          {/* <span className="name" style={{color:"#252525c9",marginLeft:"3px"}}>{props.reposters_ids[0].name+" "}</span> */}

          <span style={{display:"flex",alignItems:"center"}}>
          <span className="name" style={{color:"#252525c9",marginLeft:"5px"}}>{props.reposters_ids[0].name+" "}</span>
          {props.blueTick ? <img id="blue-tick" src={require("../images/blueTick.png")}></img> : null}
          <span>این پست را بازنشر کرد </span>
          </span>

          
          

        </div>
        </span>
        <span id="post-col3">

            {/* link to reposter profile: */}
            <Link to={"/"+props.reposters_ids[0].username+"/about"}
            state={{otherUser: {_id:props.reposters_ids[0]._id, username:props.reposters_ids[0].username, name:props.reposters_ids[0].name , job:props.reposters_ids[0].job , avatarPic:props.reposters_ids[0].avatarPic} 
            , profileOfOther:true}}>
           <img src={`${process.env.REACT_APP_BACK}/avatars/${props.reposters_ids[0].avatarPic}`}
           alt={`فلیکا ،شبکه سوارکاری - ${props.reposters_ids[0].name}`}
           ></img>
            </Link>
            
            
            
            

          </span>
      </div> : null }

 {/* ********************************************************************************************************************** */}

        <div id="post-header">

        
          <span id="post-col1">

            {/* edit options: */}
            {  JSON.parse(localStorage.getItem('flickerNetwork')) && 
            (!props.postOfOther || onlineUser.role==="manager")? 
            <SplitButton
              id={theme}
              style={{ direction: "rtl" }}
              label=""
              model={editItems}
            ></SplitButton>
            
            :null
            }

            <ConfirmDialog 
            className={theme}
            visible={showConfirmDelete} 
            onHide={() => setShowConfirmDelete(false)} 
            message="آیا از حذف این پست مطمئنید؟"
            header="تایید حذف"
            icon="pi pi-info-circle"
            acceptLabel="بله"
            rejectLabel="نه"
            acceptClassName="accept"
            rejectClassName="reject"
            accept={() => deletePost(props._id)} 
            reject={() => setShowConfirmDelete(false)} />

            <Toast ref={toast} position="top-center" />
          </span>
          <span id="post-col2">
            <div>
            <span style={{display:"flex",alignItems:"center"}}>
          <p className="name" style={{marginLeft:"3px"}}>{props.creatorName}</p>
          {props.blueTick ? <img id="blue-tick" src={require("../images/blueTick.png")}></img> : null}
          </span>
              
              <p className="job">{props.creatorJob}</p>
            </div>
          </span>
          <span id="post-col3">

            {/* link to creator profile: */}
            
            {/* if is page: */}
            {props.creatorPage!=undefined ?
            
            <Link to={"/"+props.creatorUsername+"/about"}
            state={{page: {_id:props.creator_id,username:props.creatorUsername, name:props.creatorName ,avatarPic:props.creatorAvatar}, isPage: true , isUserPage:!props.postOfOther}}>
            <img 
            src={`${process.env.REACT_APP_BACK}/avatars/${props.creatorAvatar}`}
            alt={`فلیکا شبکه اجتماعی سوارکاری - ${props.creatorName}`}
            ></img>
            </Link>
            
            
            // if is user:
            : 
            <Link to={"/"+props.creatorUsername+"/about"}
            state={{otherUser: {_id:props.creator_id, username:props.creatorUsername, name:props.creatorName , job:props.creatorJob , avatarPic:props.creatorAvatar} 
            , profileOfOther:props.postOfOther}}>
           <img src={`${process.env.REACT_APP_BACK}/avatars/${props.creatorAvatar}`}
           alt={`فلیکا شبکه اجتماعی سوارکاری - ${props.creatorName}`}
           ></img>
            </Link>
            }
            
            
            

          </span>
        </div>

        
        
       
        <div id="post-cat">

        <span className="cat-col1">
        
        { (props.category != null && props.category != "") ?
          

          <span style={{marginLeft:"15px"}} onClick={()=>navigateToShopCat()}>
            <i className="pi pi-tag"></i>
            {props.category}
            </span>
            : null }
          
          

        { (props.tags != null && props.tags != "") ?
          <span>
            {props.tags.map((tag,index) => (
            
            
            <span 
            key={index} 
            style={{marginLeft:"10px", cursor:"pointer"}}
            onClick={() => {
              navigate("/tags/"+tag)       
            }}>
                #{tag}

            </span>
            ))}

          </span>
          : null}

          </span>

          <span className="cat-col2">{new Date(props.date).toLocaleDateString('fa-IR')}</span>
        </div>
        



 


    <div ref={postRef}>

        {props.fileType === "image" ? 
        
        <div id="post-pic"
        // onClick={props.taggedUsers && props.taggedUsers.length>0 ? ()=>setShowTaggedUsers(!showTaggedUsers) : null}
        

        >
        
       
        {/* tagged users section: */}
        {props.taggedUsers && props.taggedUsers.length>0? 
        <div className={taggedUsersAnimation ? "tagUser-icon fade-in" : "tagUser-icon"}>
        <i className="pi pi-user" 
        onClick={()=>setShowTaggedUsers(!showTaggedUsers)}
        ></i>
        </div>
      : null}

        {showTaggedUsers &&
            taggedUsersData.map((user, index) => (
              
              <div
                key={index}
                className="taggedUser fade-in"
                style={{
                  position: 'absolute',
                  bottom: `${getRandomPosition(60)+10}%`,
                  left: `${getRandomPosition(60)+10}%`,
                  animationDelay:".3s"
                }}
              >

                {/* link to user profile */}
                <Link to={"/"+user.username+"/about"}
                  
                  state=
                  { user.isPage ?  {page: {_id:user._id,username:user.username, name:user.name ,avatarPic:user.avatarPic}, isPage: true}
                    : {otherUser: {_id:user._id, username:user.username, name:user.name , avatarPic:user.avatarPic} 
                    , profileOfOther:true}
                  }
                  >

                <span className="tagged-user-layout">
                
                
                  
                <img
                src={`${process.env.REACT_APP_BACK}/avatars/${user.avatarPic}`}
                alt="پستی از فلیکا، شبکه اجتماعی سوارکاری"
                ></img>
                
                <span style={{marginRight:"10px"}}>{user.name}</span>
                </span>
                </Link>
              </div>
            ))}
       
        
          {props.file != "" ? (
            <img 
            src={`${process.env.REACT_APP_BACK}/posts/${props.file}`}
            alt=
            {props.tags+            
            "-فلیکا، شبکه اجتماعی سوارکاری"}
            onClick={()=>navigate("/posts/"+props._id)} 
          ></img>
          ) : null}

        
          
        </div>
        
        
        :null}

        {props.fileType === "video" ? 
        <div id="post-video" 
          ref={playerRef} 
          // onClick={props.taggedUsers && props.taggedUsers.length>0 ? ()=>setShowTaggedUsers(!showTaggedUsers) : null}
        >


          
 {/* tagged users icon */}
        {props.taggedUsers && props.taggedUsers.length>0? 
        <div className={taggedUsersAnimation ? "tagUser-icon fade-in" : "tagUser-icon"}>
        <i className="pi pi-user"
        onClick={()=>setShowTaggedUsers(!showTaggedUsers)}
        ></i>
        </div>
      : null}

      

{showTaggedUsers &&

            taggedUsersData.map((user, index) => (
              
              <div
                key={index}
                className="taggedUser fade-in"
                style={{
                  position: 'absolute',
                  bottom: `${getRandomPosition(70)+10}%`,
                  left: `${getRandomPosition(70)+10}%`,
                  animationDelay:".3s"
                }}
              >

                

                 {/* link to user profile */}
                 <Link to={"/"+user.username+"/about"}
                  
                  state=
                  { user.isPage ?  {page: {_id:user._id,username:user.username, name:user.name ,avatarPic:user.avatarPic}, isPage: true}
                    : {otherUser: {_id:user._id, username:user.username, name:user.name , avatarPic:user.avatarPic} 
                    , profileOfOther:true}
                  }
                  >

                <span className="tagged-user-layout">
                <img
                src={`${process.env.REACT_APP_BACK}/avatars/${user.avatarPic}`}
                alt="پستی از فلیکا، شبکه اجتماعی سوارکاری"
                ></img>
                <span style={{marginRight:"10px"}}>{user.name}</span>
                </span>

                </Link>

              </div>
            ))}



          {props.file != "" ? (
            <div>
         
        
            <Player
              playsInline
              src={`${process.env.REACT_APP_BACK}/posts/${ props.file}`}
              preload="none"
              isPlaying={isPlaying}
              ref={videoRef}
              poster={`${process.env.REACT_APP_BACK}/posters/poster_${props.file.replace(/\.[^/.]+$/, '')}.png`}
              
            />
            
         
           
            </div>
          ) : null}

       

        </div>
        :null}

        </div>
        

      
        { online ? 

        <div id="post-reaction">

          <span className={"react-col1 heart-icon-"+isLiked}>
            <Button
              tooltip="لایک"
              tooltipOptions={{
                className: "create-post-tooltip",
                position: "top",
              }}
              
            >

              {/* like icon: */}
              <div>
                <UseAnimations className="animated-icon" 
                reverse={isLiked}
                onClick={like} 
                strokeColor={'#25252580'} 
                fillColor={"#EF4444"} 
                animation={heart} 
                size={30} 
                wrapperStyle={{ padding: 0 }} /></div>
              <span onClick={()=>setShowWhoLiked(true)}>{likesCount}</span>
              

              
            </Button>
            

            <Button
              tooltip="نظرات"
              tooltipOptions={{
                className: "create-post-tooltip",
                position: "top",
              }}
              onClick={() => {setShowComments(!showComments); setReply(false); setCommentPlaceHolder("نظر خود را بنویسید ...")}}
            >
              <i className="pi pi-comment"></i>
              <span>{commentsCount}</span>
            </Button>

            <Button
              tooltip="به اشتراک گذاری"
              tooltipOptions={{
                className: "create-post-tooltip",
                position: "top",
              }}
              onClick={(e) => {op.current.toggle(e)}}
            >
              <i className="pi pi-share-alt"></i>
              <span></span>
            </Button>


            <OverlayPanel ref={op} dismissable
            className={theme}>

              <div>
              <Button
              tooltip="کپی لینک"
              tooltipOptions={{
                className: "create-post-tooltip",
                position: "top",
              }}
              onClick={() => {
                navigator.clipboard.writeText("https://flicka.ir/posts/"+props._id);
                toast.current.show({severity: 'success', detail: 'لینک در حافظه کپی شد'});
              }}

              style={{padding:"6px" , marginBottom:"8px"}}
            >
              <i className="pi pi-link"></i>
              <span></span>
            </Button>

              </div>
              
            
            <div>

            <LinkedinShareButton 
            url={"http://flicka.ir/posts/"+props._id}
            title="یه پست جذاب از فلیکا"
            summary={props.description}
        >
            <LinkedinIcon style={{ opacity: '1'}} size={28} round={true}/>
        </LinkedinShareButton>
        </div>

        <div>
        <WhatsappShareButton 
            url={"http://flicka.ir/posts/"+props._id}
            title="یه پست جذاب از فلیکا"
            summary={props.description}
        >
            <WhatsappIcon style={{ opacity: '1'}} size={28} round={true}/>
        </WhatsappShareButton>
        </div>

        <div>
        <FacebookShareButton 
            url={"http://flicka.ir/posts/"+props._id}
            title="یه پست جذاب از فلیکا"
            summary={props.description}
        >
            <FacebookIcon style={{ opacity: '1'}} size={28} round={true}/>
        </FacebookShareButton>
        </div>
        
            <EmailShareButton 
            url={"http://flicka.ir/posts/"+props._id}
            title="یه پست جذاب از فلیکا"
            summary={props.description}
        >
            <EmailIcon style={{ opacity: '1'}} size={28} round={true}/>
        </EmailShareButton>

        
        
    
            </OverlayPanel>
 

          

            {props.category !=="لوازم اسب" && props.category!=="لوازم سوار" ? 
            
            <Button
            tooltip="باز نشر"
            onClick={()=>setShowRepost(true)}
            tooltipOptions={{
              className: "create-post-tooltip",
              position: "top",
            }}
            
          >
            <i className="pi pi-sync"></i>
            <span></span>
          </Button>
            

            :

            <Button
              tooltip="سوال از فروشنده"
              tooltipOptions={{
                className: "create-post-tooltip",
                position: "top",
              }}>
              
            
            <i 
            className="pi pi-question" style={{backgroundColor:"rgb(175, 115, 79)", color:"#ffffff",borderRadius:"50%",fontSize:"18px",padding:"5px"}}
            onClick={()=> 
              
              {
                // if is page (from shop):
                if(props.creatorPage)
                    navigate("/"+props.creatorUsername+"/about",{
                      state:  
                      {
                        page:props.creatorPage,
                        profileOfOther:props.postOfOther, 
                        message:false, 
                        isPage:true
                      }});
                //if is a page(from page)
                else if(props.page)
                navigate("/"+props.creatorUsername+"/about",{
                  state:  
                  {
                    otherUser: {_id:props.page.creator_id._id, username:props.page.creator_id.username, name:props.page.creator_id.name , job:props.page.creator_id.job , avatarPic:props.page.creator_id.avatarPic},
                    profileOfOther:props.postOfOther,
                    message:true, 
                    isPage:false
                  }});

              //if user:
              else
              navigate("/"+props.creatorUsername+"/about",{
                state:  
                {
                  otherUser: {_id:props.creator_id, username:props.creatorUsername, name:props.creatorName , job:props.creatorJob , avatarPic:props.creatorAvatar},
                profileOfOther:props.postOfOther, 
                message:true, 
                isPage:false
                }});

           
            
            }}>
                </i>
              

              
            </Button>

}
          </span>

          <span className="react-col2 save-icon">
            <Button
              tooltip="ذخیره"
              tooltipOptions={{
                className: "create-post-tooltip",
                position: "top",
              }}
            >
              {/* save icon */}
              <div>
                <UseAnimations 
                className="animated-icon" 
                reverse={isSaved}
                onClick={save} 
                strokeColor={'#25252580'} 
                fillColor={"#25252580"} 
                animation={bookmark} 
                size={30} 
                wrapperStyle={{ padding: 0 , marginTop: "1px"}} />
                </div>
              <span></span>
            </Button>
          </span>
        </div>

        : null }







        


        { props.description != null && props.description != "" ?

        <div id="post-content">

                  

                  
        <div
        ref={descRef}
        style=
        { descriptionLinesCount>4 && showViewMore ?
          { overflow: "hidden",display: "-webkit-box",textOverflow: "ellipsis",
        WebkitLineClamp: "4",WebkitBoxOrient: "vertical",lineHeight: "30px"} : {} }
        >
          {splitTextByLines(props.description)}
          </div>




          {descriptionLinesCount>4 && showViewMore && (
          
            
            <p
              className="view-more-button"
              onClick={() => setShowViewMore(!showViewMore)}
              style={{cursor:"pointer"}}
            >
              {"بیشتر >"}
            </p>
          
        )}


        {descriptionLinesCount>4 && !showViewMore && (
          <div>
            {" "}
            <p
              className="view-more-button"
              onClick={() => setShowViewMore(!showViewMore)}
              style={{cursor:"pointer"}}
            >
              {" "}
              {"< کمتر "}
            </p>
          </div>
        )}


        </div>
          
          
        :null }


              
        <Fieldset toggleable collapsed={!showComments} onToggle={(e) => setShowComments(true)}>
        

          { online ? 
        <div id="comments" >

        {comments.map((comment, index) => { 
          
       
          
          return(

          <div key={index}>
           

          <Comment 
            comment={comment} 
            replies={replies}
            decrementCommentsCount={()=> setCommentsCount(commentsCount-1)} 
            setShowComments={(value)=>setShowComments(value)} 
            replyClicked={(replyToData)=>replyClicked(replyToData)}
            nestedReply={false}
            showViewMore={true}
            />

            
            

            </div>

      
            

    

          )
                      }
                      )}

          
        </div>

        : null}
        </Fieldset>

{ online ? 
        <div id="comments" style={{marginTop: "0px",marginBottom: "10px"}}>

        {/* reply banner */}

        <Fieldset toggleable collapsed={!reply} onToggle={(e) => replyClicked(replyToData)}>
          <div id="post-reply-banner">
            <span className="row1" style={{display:"flex",direction:"rtl",alignItems:"center"}}>

              <i className="pi pi pi-times" 
              style={{display:"flex",height:"10%",marginLeft:"10px", fontSize:"12px",cursor:"pointer",color:"#af734f",background: "rgb(175 115 79 / 24%)",padding: "5px",borderRadius: "50%"}} 
              onClick={() => setReply(false)}></i>
              
            <span className="reply-to-text" style={{color:"rgba(175, 115, 79, 0.63)",fontSize:"13px"}}>پاسخ به:  </span>
            {replyToData && <span className="reply-to-name" style={{color:"#49505796",fontSize:"13px"}}>{" "+replyToData.name+"   "}</span>}
            
              
            </span>

          </div>
          <div class="post-chat-arrow"></div>



          </Fieldset>

        <div id="new-comment">
            <span className="row1">
              
            <img src={`${process.env.REACT_APP_BACK}/avatars/${props.userAvatar}`}
            alt={`فلیکا ، شبکه اجتماعی سوارکاری - ${props.user_name}`}
            ></img>
              
            </span>
            <span className="row2 search-box-wraper">

            <form onSubmit={formik.handleSubmit}>
              
              <InputText
                id="content"
                name="content"
                className="search-box"
                value={formik.values.content}
                placeholder={commentPlaceholder}
                onChange={formik.handleChange}
              />
              </form>
              <a style={{color:"#af734f", marginLeft:"8px"}} onClick={formik.handleSubmit}>ارسال</a>

            </span>


          </div>
          </div>
          : null }

      </Card>
    </div>
  );
}

export default Post;
